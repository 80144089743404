import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { getSession } from "lib/utils";
import { EvendyApi } from "lib/utils/api-utils";
import { useMemo } from "react";

interface Vendor {
  id: number;
  vendorEmail: string;
  businessName: string;
  businessCategoryName: string | null;
  description: string | null;
  phone: string | null;
  avatar: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  address: string | null;
  workAddress: string | null;
  kycLevel: string;
  role: string;
  accountStatus: string;
  createdAt: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
}

type VendorResult = UseQueryResult<Vendor, Error>;

export function useGetVendorByEmail(): VendorResult {
  const session = getSession();

  const email = useMemo(() => session?.email, [session]);

  return useQuery({
    queryKey: [tqQueryKeys.vendors.getVendorById, email],
    queryFn: async (): Promise<Vendor> => {
      const res = await EvendyApi.get<Vendor>(`vendor/get-by-email/${email}`);
      return res.data;
    },
    enabled: !!email,
    retry: false,
  });
}
