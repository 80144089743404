import { useQuery } from "@tanstack/react-query";
import { EvendyApi } from "lib/utils/api-utils";
import { tqQueryKeys } from "lib/constants";

export interface OrganizerOverviewResponse {
  currentBalance: number;
  totalEventsCreated: number;
  upcomingEvents: number;
  eventsConcluded: number;
  totalExpenses: number;
  totalRevenue: number;
}

export function useGetOrganizerOverview() {
  return useQuery<OrganizerOverviewResponse, Error>({
    queryKey: [tqQueryKeys.organizer.overview], // Unique key for caching
    queryFn: async () => {
      const response = await EvendyApi.get<OrganizerOverviewResponse>(
        "organizer/custom/overview", // Correct endpoint
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Assuming the token is stored in localStorage
          },
        },
      );
      return response.data; // Return the response data
    },
  });
}
