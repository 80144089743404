// import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
// import { tqMutationKeys, tqQueryKeys } from "lib/constants";
// import { EvendyApi } from "lib/utils/api-utils";
// import { ISODateString } from "ui/types";

// export interface CreateEventPayload {
//   title: string;
//   banner: string;
//   organizerEmail: string;
//   category: string;
//   about: string;
// }

// export interface CreateEventResponse {
//   id: number;
//   title: string;
//   banner: string;
//   organizerEmail: string;
//   category: string;
//   type: string;
//   about: string;
//   occurence: string;
//   timezone: string;
//   startDate: ISODateString;
//   endDate: ISODateString;
//   createdAt: ISODateString;
//   updatedAt: ISODateString;
// }

// type UseCreateEventResult = UseMutationResult<CreateEventResponse, Error, CreateEventPayload>;

// export function useCreateEvent(): UseCreateEventResult {
//   const queryClient = useQueryClient();

//   return useMutation({
//     mutationFn: async (payload: CreateEventPayload) => {
//       const formData = new FormData();
//       formData.append("title", payload.title);
//       formData.append("banner", payload.banner); // Add the File object
//       formData.append("organizerEmail", payload.organizerEmail);
//       formData.append("category", payload.category);
//       formData.append("about", payload.about);

//       const res = await EvendyApi.post<CreateEventResponse>("event", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data", // Set multipart headers
//         },
//       });
//       return res.data; // Automatically unwrap the Promise
//     },
//     mutationKey: [tqMutationKeys.event.create],
//     onError(createEventError: Error) {
//       console.error("Error creating event:", createEventError);
//     },
//     onSuccess(data) {
//       queryClient.invalidateQueries({ queryKey: [tqQueryKeys.event.getEvents] });
//       console.log("Event created successfully:", data);
//     },
//   });
// }

import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { tqMutationKeys, tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { ISODateString } from "ui/types";

export interface CreateEventPayload {
  title: string;
  organizerEmail: string;
  category: string;
  about: string;
}

export interface CreateEventResponse {
  id: number;
  title: string;
  organizerEmail: string;
  category: string;
  type: string;
  about: string;
  occurence: string;
  timezone: string;
  startDate: ISODateString;
  endDate: ISODateString;
  createdAt: ISODateString;
  updatedAt: ISODateString;
}

type UseCreateEventResult = UseMutationResult<CreateEventResponse, Error, CreateEventPayload>;

export function useCreateEvent(): UseCreateEventResult {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: CreateEventPayload) => {
      const res = await EvendyApi.post<CreateEventResponse>("event", payload);
      return res.data; // Automatically unwrap the Promise
    },
    mutationKey: [tqMutationKeys.event.create],
    onError(createEventError: Error) {
      console.error("Error creating event:", createEventError);
    },
    onSuccess(data) {
      queryClient.invalidateQueries({ queryKey: [tqQueryKeys.event.getEvents] });
      console.log("Event created successfully:", data);
    },
  });
}
