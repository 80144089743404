import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { ISODateString } from "ui/types";

interface Event {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  type: string;
  about: string;
  occurence: string;
  timezone: string;
  startDate: ISODateString;
  endDate: ISODateString;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  location: string;
}
interface ExploreEvent {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  type: string;
  about: string;
  organizer: {
    id: number;
    avatar: string;
    user: {
      firstName: string;
      lastName: string;
      email: string;
    };
  };
  occurence: string;
  timezone: string;
  startDate: ISODateString;
  endDate: ISODateString;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  location: string;
}

type EventResult = UseQueryResult<ExploreEvent, Error>;

type GetEventPayload = {
  id: number;
};

export function useGetEventExploreByEventId(payload: GetEventPayload): EventResult {
  const { id } = payload;

  return useQuery({
    queryKey: [tqQueryKeys.event.getExploreEventById, id],
    queryFn: async (): Promise<ExploreEvent> => {
      const res = await EvendyApi.get<ExploreEvent>(`event/explore/${id}`);
      return res.data;
    },
    enabled: !!id,
    retry: false,
  });
}
