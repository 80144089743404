import React, { useState, useMemo, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { Form, Input, Select, Button, message, notification } from "antd";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { useCreateEvent, useGetUser, useGetEventById, useUploadBanner } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { routeNames } from "lib/routes";
import { useGetEventCategories } from "lib/hooks/eventcategory";
import { useEventContext } from "ui/components/event/eventContext";
import { CreateEventResponse, CreateEventPayload, UploadBannerPayload } from "lib/hooks";
import { useEditEventById } from "lib/hooks";
import { ReactComponent as UploadLogo } from "images/organizerevents/upload.svg";
import { ReactComponent as EditIcon } from "images/organizerevents/edit-icon.svg";
import { EditOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: end;
  gap: 30px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const MediumText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const SmallText = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-align: center;
`;

const Bluespan = styled.span`
  color: #1390e6;
`;

const GhostButton = styled(Button)`
  width: 127px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  background-color: #fff;
`;

const ButtonWrapper = styled.div`
  width: 205px;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed #1390e6;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #f9faff;
  &:hover {
    border-color: #0d6efd;
  }
`;

const ImagePreviewContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 1rem;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 200px;
  border-radius: 8px;
`;

const EditIconWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const initialEventValues = {
  title: "",
  banner: "",
  organizerEmail: "",
  category: "",
  about: "",
};

const CreateEventDetail = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { data: user } = useGetUser();
  const { mutate: createEvent } = useCreateEvent();
  const { mutate: uploadBanner } = useUploadBanner();
  const { mutate: updateEvent } = useEditEventById();
  const { data: categories, isLoading: isCategoriesLoading, error: categoriesError } = useGetEventCategories();
  const userEmail = user?.email;
  const { eventId: rawEventId } = useEventContext();
  const eventId = rawEventId ? Number(rawEventId) : null;
  const { setEventId } = useEventContext();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [showDropzone, setShowDropzone] = useState(true);

  const organizerName = useMemo(() => {
    if (user) return `${user.firstName} ${user.lastName}`;
    return "";
  }, [user]);

  const organizerEmail = useMemo(() => {
    const session = getSession();
    return user?.email || session?.email;
  }, [user]);

  const useGetEventByIdSafe = (eventId: number | null) => {
    return eventId ? useGetEventById({ id: eventId }) : { data: null, error: null };
  };

  const { data: event, error: eventError } = useGetEventByIdSafe(eventId);

  useEffect(() => {
    if (event) {
      form.setFieldsValue({
        title: event.title,
        organizer: event.organizerEmail,
        category: event.category,
        about: event.about,
        banner: event.banner,
      });
    }
  }, [event, form]);

  const handleFinish = async (values: any) => {
    setIsSubmitting(true);
    if (!organizerEmail) {
      notification.error({ message: "Unable to find organizer details, kindly try again" });
      setIsSubmitting(false);
      return;
    }

    const payload: CreateEventPayload = {
      title: values.title.trim(),
      organizerEmail,
      category: values.category.trim(),
      about: values.about.trim(),
    };

    if (eventId) {
      updateEvent(
        { id: eventId, data: payload },
        {
          onSuccess: () => {
            message.success("Event updated successfully");
            navigate(routeNames.eventType);
          },
          onError: handleError,
        },
      );
    } else {
      createEvent(payload, {
        onSuccess: async (data: CreateEventResponse) => {
          setEventId(Number(data.id));
          message.success("Event created successfully");

          if (uploadedFile) {
            const bannerPayload: UploadBannerPayload = {
              eventTitle: values.title.trim(),
              banner: uploadedFile,
            };

            try {
              await uploadBanner(
                { eventId: Number(data.id), payload: bannerPayload },
                {
                  onSuccess: () => {
                    message.success("Banner uploaded successfully");
                  },
                  onError: error => {
                    console.error("Error uploading banner:", error);
                    message.error("Failed to upload banner");
                  },
                },
              );
            } catch (error) {
              console.error("Error uploading banner:", error);
              message.error("Failed to upload banner");
            }
          }

          form.resetFields();
          navigate(routeNames.eventType);
        },
        onError: handleError,
      });
    }
  };

  const handleError = (error: Error) => {
    message.error(
      axios.isAxiosError(error)
        ? error.response?.data?.message || "Failed to process request."
        : "An unexpected error occurred.",
    );
    setIsSubmitting(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxFiles: 1,
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];
      if (file) {
        setUploadedFile(file);
        setShowDropzone(false);
        form.setFieldsValue({ banner: file });
      }
    },
  });

  const handleEditClick = () => {
    setShowDropzone(true);
  };

  return (
    <Container>
      <MediumText>Event Details</MediumText>
      <Form
        layout="vertical"
        style={{ width: "100%", margin: "0 auto" }}
        form={form}
        onFinish={handleFinish}
        initialValues={initialEventValues}
        requiredMark={false}
      >
        <Form.Item label="Event Banner" name="banner">
          {showDropzone ? (
            <DropzoneContainer {...getRootProps()}>
              <input {...getInputProps()} />
              <UploadLogo />
              <SmallText>
                <Bluespan>Click to upload</Bluespan> or drag and drop
              </SmallText>
              <SmallText>PNG, JPG (max. 800x400px)</SmallText>
            </DropzoneContainer>
          ) : (
            <ImagePreviewContainer>
              <ImagePreview src={URL.createObjectURL(uploadedFile!)} alt="Uploaded Banner" />
              <EditIconWrapper onClick={handleEditClick}>
                <EditOutlined />
              </EditIconWrapper>
            </ImagePreviewContainer>
          )}
        </Form.Item>

        <Form.Item
          label="Event Title"
          name="title"
          rules={[{ required: true, message: "Please enter the event title" }]}
        >
          <Input placeholder="Enter the event title" />
        </Form.Item>

        <Form.Item label="Organizer">
          <Input value={organizerName} readOnly />
        </Form.Item>

        <Form.Item
          label="Event Category"
          name="category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select
            placeholder="Select category"
            showSearch
            loading={isCategoriesLoading}
            filterOption={(input, option) =>
              typeof option?.children === "string"
                ? String(option.children).toLowerCase().includes(input.toLowerCase())
                : false
            }
          >
            {categoriesError && <Option disabled>Error fetching categories</Option>}
            {categories?.map(category => (
              <Option key={category.id} value={category.name}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="About Event" name="about" rules={[{ required: true, message: "Please describe the event" }]}>
          <TextArea rows={4} placeholder="Describe the event for attendees to know what's up" />
        </Form.Item>

        <ButtonContainer>
          <Link to={routeNames.organizerHome}>
            <GhostButton>Cancel</GhostButton>
          </Link>
          <ButtonWrapper>
            <SubmitButton form={form} loading={isSubmitting}>
              Save and Continue
            </SubmitButton>
          </ButtonWrapper>
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default CreateEventDetail;
