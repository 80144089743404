export const routeNames = {
  homepage: "/",
  servicesHomePage: "/services",
  financialServices: "/services/finance",
  ticketingPage: "/services/ticketingPage",
  analysisPage: "/services/EventAnalysisPage",
  eventManagement: "/services/EventManagementPage",
  eventMarketing: "/services/EventMarketingPage",
  eventVendorSourcing: "/services/EventVendorSourcing",

  //event page on the landing page
  event: "/event/event",
  eventDetail: "/event/eventDetail",
  eventPreview: "/event/preview",

  //buy tickets on landing page
  ticket: "/get-ticket",
  ticketSelect: "/get-ticket/selectticket",
  ticketDetails: "/get-ticket/yourdetails",
  ticketCheckout: "/get-ticket/checkout",
  ticketConfirmation: "get-ticket/checkout/confirmation",

  //aboutus page
  about: "/about/about",
  //contact page
  contact: "/contact/contact",
  contactForm: "/contact/contact-form",
  // privacy
  privacy: "/privacy/privacy",
  // terms and conditions
  terms: "/terms/terms",
  dashboard: "/app",
  // vendors
  vendor: "/app/vendor",
  vendorHome: "/app/vendor/home",
  VendorMyPackages: "/app/vendor/mypackages",
  VendorRequests: "/app/vendor/requests",
  VendorResources: "/app/vendor/resources",
  vendorProfile: "/app/vendor/profile",

  //organizer
  organizer: "/app/organizer",
  organizerHome: "/app/organizer/home",
  organizerEvents: "/app/organizer/events",
  organizerApp: "/app/organizer",
  organizermarketplace: "/app/marketplace",
  organizerLoan: "/app/organizer/loan",
  organizerReports: "/app/organizer/reports",
  organizerProfile: "/app/organizer/profile",
  organizerVendorProfile: "/app/organizer/vendorprofile",
  organizerEventManagement: "/app/organizer/eventmanagement",

  newEvent: "/app/organizer/createevent",
  eventType: "/app/organizer/createevent/eventtype",
  eventDate: "/app/organizer/createevent/eventdate",
  eventDetails: "/app/organizer/createevent/eventdetail",
  eventTickets: "/app/organizer/createevent/eventtickets",

  //organizer and vendor
  finances: "/app/finances",
  marketing: "/app/marketing",
  hospitality: "/app/hospitality",
  help: "/app/help",

  // authentication
  auth: "/auth",
  logout: "/logout",
  authSignup: "/auth/signup",
  authSignupForm: "/auth/signup/form",
  authVerifyEmail: "/auth/verifyemail",
  authSignupSuccessful: "/auth/signupsuccessful",
  authSignupComplete: "/auth/signupcomplete",
  authLogin: "/auth/login",
  authForgotPassword: "/auth/forgotpassword",
  authResetPassword: "/auth/resetpassword",
  authPasswordUpdated: "/auth/passwordupdated",

  //dasboard email verifiction
  VerifyEmail: "/VerifyEmail",

  //admin login
  admin: "/evendy",

  // admin
  adminHome: "/evendy/home",
  adminEvents: "/evendy/events",
  adminOrganizers: "/evendy/organizers",
  adminVendors: "/evendy/vendors",
  adminVendorRequest: "/evendy/vendorrequest",
  adminDisputes: "/evendy/disputes",
  adminEventPreview: "/evendy/eventmanagement",

  attendees: "/attendees",
  attendeesPlans: "/attendees/plans",
  attendeesPayment: "/attendees/payment",
  attendeesPaymentSucces: "/attendees/paymentSuccess",
  attendeesHowItWorks: "/attendees/howitworks",
  attendeesHome: "/attendees/home",
  attendeesEvent: "/attendees/events",
  attendeesTicketDetails: "/attendees/ticketdetails",

  // withdrawal:"/withdraw",
  // withdrawalSuccessful: "/withdraw/success",
};
