import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

interface GetVendorRequestDto {
  id: number;
  eventTitle: string;
  organizerEmail: string;
  packageId: number;
  vendorEmail: string;
  quantity: number;
  status: string;
}

type VendorRequestResult = UseQueryResult<GetVendorRequestDto[], Error>;

export type GetVendorRequestPayload = {
  page?: number;
  limit?: number;
  status?: string;
};

export function useGetVendorRequest(payload: GetVendorRequestPayload): VendorRequestResult {
  const { page, limit, status } = payload;

  return useQuery({
    queryKey: [tqQueryKeys.vendorRequest.getVendorRequest, page, limit, status],
    queryFn: async (): Promise<GetVendorRequestDto[]> => {
      const res = await EvendyApi.get<GetVendorRequestDto[]>("vendor-request", {
        params: { page, limit, status },
      });
      return res.data;
    },
    enabled: !!page && !!limit && !!status,
    retry: false,
    // placeholderData: generateDummyData(10),
  });
}
