import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AppModal } from "ui/components/base";
import { Button, Form, Input } from "antd";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { ReactComponent as Logoimg } from "images/admin/Logoevendylogo.svg";
import { useAdminLogin } from "lib/hooks/auth/useAdminLogin";
import Swal from "sweetalert2";
import { routeNames } from "lib/routes";
import { useNavigate } from "react-router-dom";
import { sessionStorageKeys } from "lib/constants"; // Import sessionStorageKeys

export const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Header = styled.h3`
  font-size: 21px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const SmallText = styled.p`
  font-size: 12px;
  color: "#9c9c9c";
  margin-top: 0;
`;

const Email = styled.div`
  width: calc(100% - 10px);
`;

const Password = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const Admin: React.FC = () => {
  const [form] = Form.useForm();
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const { mutate: login, isError, error } = useAdminLogin();
  const [promptShown, setPromptShown] = useState(false);
  const navigate = useNavigate();

  const handleRequestModalClose = () => {
    setRequestModalOpen(false);
  };

  const onFinish = (values: any) => {
    login(values, {
      onSuccess: () => {
        console.log("Login successful");
        navigate(routeNames.adminHome);
      },
      onError: loginError => {
        console.error("Login error: ", loginError);
      },
    });
  };

  useEffect(() => {
    if (!promptShown) {
      Swal.fire({
        title: "Key",
        html: '<input type="key" id="swal-key" class="swal2-input" placeholder="key">',
        focusConfirm: false,
        showCancelButton: true,
        preConfirm: () => {
          const key = (document.getElementById("swal-key") as HTMLInputElement).value;
          const credentials = key;
          const encodedCredentials = btoa(credentials); // Base64 encode

          // Use sessionStorageKeys.encodedCredentials instead of hardcoding the key
          sessionStorage.setItem(sessionStorageKeys.encodedCredentials, encodedCredentials);

          if (!key) {
            Swal.showValidationMessage("key");
            return false;
          }
          return { key };
        },
      }).then(result => {
        if (result.isConfirmed) {
          setRequestModalOpen(true);
        }
      });

      setPromptShown(true);
    }
  }, []);

  return (
    <AppModal
      open={requestModalOpen}
      onCancel={handleRequestModalClose}
      header={<span></span>}
      size="md"
      hideHeader={true}
    >
      <ModalBodyContainer>
        <Logo>
          <Logoimg />
        </Logo>
        <Top>
          <Header>Login</Header>
          <SmallText>To access your account provide your details below</SmallText>
        </Top>
        <Form layout="vertical" form={form} name="login" onFinish={onFinish} scrollToFirstError requiredMark={false}>
          <Email>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
          </Email>
          <Password>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password placeholder="Enter password" />
            </Form.Item>
          </Password>
          <Form.Item>
            <SubmitButton form={form}>Log in</SubmitButton>
          </Form.Item>
        </Form>
        {isError && <p style={{ color: "red" }}>{(error as Error).message}</p>}
      </ModalBodyContainer>
    </AppModal>
  );
};

export default Admin;
