import React, { useState } from "react";
import styled from "styled-components";
import { useGetEvents, useGetEventExplore } from "lib/hooks";
import { Pagination } from "antd";

import Card from "./EventCard";

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 19px;
  justify-content: space-between;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
interface EventListProps {
  searchTerm: string;
}

const EventList: React.FC<EventListProps> = ({ searchTerm }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(12);

  const {
    data: eventResponse,
    isLoading,
    isError,
  } = useGetEventExplore({
    page: currentPage,
    limit: pageSize,
    searchstring: searchTerm,
    timezone: "",
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching events</div>;

  return (
    <>
      <CardContainer>
        {!isLoading && !isError && eventResponse?.data?.map?.(event => <Card key={event.id} event={event} />)}
      </CardContainer>
      {!isLoading && !isError && (
        <PaginationContainer>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={eventResponse?.totalCount || 0}
            onChange={handlePageChange}
            size="small"
          />
        </PaginationContainer>
      )}
    </>
  );
};

export default EventList;
