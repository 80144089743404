import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Divider, message } from "antd";
import { routeNames } from "lib/routes";
import { ReactComponent as Google } from "images/modal/google.svg";
import { ReactComponent as Apple } from "images/modal/apple.svg";
import { useLogin } from "lib/hooks";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { sessionStorageKeys } from "lib/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { width } from "styled-system";
import { AppFlex, maxModalWidth } from "ui/components/base";
import { UserType } from "ui/types";
import { ReactComponent as Check } from "images/modal/Frameselect.svg";
import { appGet, getSession } from "lib/utils";
import { AxiosError } from "axios";
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  /* width: ${maxModalWidth}; */
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h3`
  font-size: 21px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const LargeText = styled.p`
  font-size: 13px;
`;

const SmallText = styled.p`
  font-size: 12px;
  color: "#9c9c9c";
  margin-top: 0;
`;

const Email = styled.div`
  width: calc(100% - 10px);
`;

const Password = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const ForgotPasswordCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -1rem;
  margin-bottom: 1.5rem;
`;
const ForgotPassword = styled.div`
  color: #1390e6;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
`;

const RememberMe = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const Linkto = styled.span`
  color: #1390e6;
  font-weight: 600;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Line1 = styled.div`
  margin-top: -2rem;
  width: 100%;
`;

const Line2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const Line3 = styled.div``;

const SignupSocial = styled.button`
  background-color: white;
  color: black;
  border: 1px solid;
  border-color: #e2e8f0;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const UserTypeLabel = styled.p<{ selected: boolean }>`
  font-size: 0.8rem;
  color: ${({ selected }) => (selected ? "#1C5B87" : "black")};
`;

const ButtonBase = styled.button<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? "#FCFFFF" : "white")};
  color: black;
  outline: 1px solid;
  outline-color: ${({ selected }) => (selected ? "#1C5B87" : "#f1f1f1")};
  font-weight: 500;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 1rem;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: #fcffff;
    outline-color: ${({ selected }) => (selected ? "#1C5B87" : "#d9d9d9")};
  }
`;

const UserTypeButton = styled(ButtonBase)`
  width: 50%;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const ToggleView = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

const onChange: CheckboxProps["onChange"] = e => {
  console.log(`checked = ${e.target.checked}`);
};

const LoginPage: React.FC = () => {
  const [userType, setUserType] = React.useState<UserType | null>(null);
  const [form] = Form.useForm();
  const { mutate: login, isError, error, isSuccess } = useLogin();
  const navigate = useNavigate();
  // const userType = "vendor";

  // Google reCAPTCHA
  const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA || "";
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  console.log("ReCAPTCHA Key:", recaptchaKey);

  const handleSelectType = (type: UserType) => {
    setUserType(type);
    form.setFieldValue("userType", userType);
    sessionStorage.setItem(sessionStorageKeys.userType, type);
  };

  useEffect(() => {
    if (!recaptchaKey) {
      console.error("REACT_APP_GOOGLE_RECAPTCHA is not defined");
      message.error("reCAPTCHA key is missing.");
    }
  }, [recaptchaKey]);

  const handleRecaptchaVerify = (token: string | null) => {
    console.log("ReCAPTCHA token received:", token);
    setRecaptchaToken(token);
  };

  useEffect(() => {
    if (isSuccess) {
      const session = getSession();
      if (session?.userType) {
        navigate(`/app/${session.userType}/home`);
      } else {
        console.error("User type not found in session storage");
      }
    }
  }, [isSuccess, navigate]);

  const onFinish = async (values: any) => {
    // userType
    // if (!userType) {
    //   message.error("Please select which account type you're loggin in.");
    //   return;
    // }
    // Google reCAPTCHA
    if (!recaptchaToken) {
      message.error("Please complete the reCAPTCHA verification.");
      return;
    }
    console.log("Form values:", values);
    console.log("ReCAPTCHA token before login:", recaptchaToken);
    login(values, {
      onSuccess: () => {
        console.log("Login successful");
      },
      onError: loginError => {
        console.error("Login error: ", loginError);
        if (loginError instanceof AxiosError) {
          const statusCode = appGet(loginError, "response.data.statusCode", 0);
          const message: string = appGet(loginError, "response.data.message", "unknown Error");
          // email unverified
          if (statusCode === 401 && message.toLocaleLowerCase().includes("verified")) {
            sessionStorage.setItem(sessionStorageKeys.signupEmail, values.email);
            navigate(routeNames.authVerifyEmail);
          }
        }
      },
    });
  };

  return (
    <Container>
      <Top>
        <Header>Login</Header>
        <SmallText>You left? We noticed. Get back in then!</SmallText>
      </Top>
      <Form layout="vertical" form={form} name="register" scrollToFirstError requiredMark={false} onFinish={onFinish}>
        <Email>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              placeholder="e.g example@email.com"
              style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
            />
          </Form.Item>
        </Email>
        <Password>
          <Form.Item
            label="Password"
            style={{ width: "100%" }}
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Enter password" style={{ fontSize: "14px", color: "#9C9C9C" }} />
          </Form.Item>
        </Password>
        <ForgotPasswordCont>
          <RememberMe>
            <Checkbox onChange={onChange}> Remember me</Checkbox>
          </RememberMe>
          <StyledLink to={routeNames.authForgotPassword}>
            <ForgotPassword>Forgot password?</ForgotPassword>
          </StyledLink>
        </ForgotPasswordCont>
        <Form.Item>
          <ReCAPTCHA sitekey={recaptchaKey} onChange={handleRecaptchaVerify} />
        </Form.Item>
        {isError && (
          <p style={{ color: "red" }}>
            {appGet(
              error,
              "response.data.message",
              error?.message || "Something went wrong logging in, kindly try again",
            )}
          </p>
        )}{" "}
        {/* Display error message */}
        <Form.Item>
          <SubmitButton form={form}>Login</SubmitButton>
        </Form.Item>
      </Form>
      <Bottom>
        <Line1>
          <Divider plain>or</Divider>
        </Line1>
        <Line2>
          <SignupSocial>
            <Google />
            Continue with Google
          </SignupSocial>
          <SignupSocial>
            <Apple />
            Continue with Apple
          </SignupSocial>
        </Line2>
        <Line3>
          <LargeText>
            Don’t have an account?{" "}
            <StyledLink to={routeNames.authSignup}>
              <Linkto> Create one here!</Linkto>
            </StyledLink>
          </LargeText>
        </Line3>
      </Bottom>
    </Container>
  );
};

export default LoginPage;
