import React, { useEffect, useState } from "react";
import { Card, Button } from "antd";
import styled from "styled-components";
import { Link, Outlet, NavLink, useParams, useNavigate } from "react-router-dom";
import { useGetEventTicketsByEventId } from "lib/hooks/ticketing";
import { routeNames } from "lib/routes";
import { useTicketContext } from "lib/context/ticketpurchase/ticketContext";
const Container = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-right: 1rem;
`;

const SelectTicketContainer = styled.div`
  padding: 8px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 10px;
  border: 1px solid rgba(226, 232, 240, 1);
`;

const TicketType = styled.div``;

const TicketQty = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  max-width: 333px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const TicketAmount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #595959;
`;

const MediumText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #121212;
`;

const BoldText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #121212;
`;

const Decrement = styled(Button)`
  width: 24px;
  height: 24px;
  padding: 2px 2px 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cecece;
`;

const Increment = styled(Button)`
  width: 24px;
  height: 24px;
  padding: 2px 2px 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1390e6;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`;

const Cancel = styled.button`
  background-color: #ffffff00;
  color: #595959;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  padding: 10px 10px;
  font-size: 13px;
  width: 129px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Continue = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 129px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const SelectTicket: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();
  const [eventId, setEventId] = useState<number>(0);
  const { ticketData, updateTicketData } = useTicketContext();

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : undefined;
    if (id) setEventId(id);
  }, [params.id]);

  const { data: tickets, isLoading, isError } = useGetEventTicketsByEventId({ eventid: eventId });

  // const increment = (ticketId: number, price: number) => {
  //   const updatedOrders = ticketData.orders.map(order =>
  //     order.eventTicketId === ticketId ? { ...order, count: order.count + 1 } : order,
  //   );
  //   if (!updatedOrders.some(order => order.eventTicketId === ticketId)) {
  //     updatedOrders.push({ eventTicketId: ticketId, count: 1 });
  //   }
  //   updateTicketData({ orders: updatedOrders }, tickets || []);
  // };

  // const decrement = (ticketId: number) => {
  //   const updatedOrders = ticketData.orders
  //     .map(order => (order.eventTicketId === ticketId ? { ...order, count: Math.max(order.count - 1, 0) } : order))
  //     .filter(order => order.count > 0);
  //   updateTicketData({ orders: updatedOrders }, tickets || []);
  // };

  const increment = (ticketId: number, price: number, ticketName: string) => {
    const updatedOrders = ticketData.orders.map(order =>
      order.eventTicketId === ticketId ? { ...order, count: order.count + 1 } : order,
    );
    if (!updatedOrders.some(order => order.eventTicketId === ticketId)) {
      updatedOrders.push({ eventTicketId: ticketId, count: 1, ticketName, individualAmount: price });
    }
    updateTicketData({ orders: updatedOrders }, tickets || []);
  };

  const decrement = (ticketId: number) => {
    const updatedOrders = ticketData.orders
      .map(order => (order.eventTicketId === ticketId ? { ...order, count: Math.max(order.count - 1, 0) } : order))
      .filter(order => order.count > 0);
    updateTicketData({ orders: updatedOrders }, tickets || []);
  };

  const getTicketCount = (ticketId: number) => {
    const order = ticketData.orders.find(order => order.eventTicketId === ticketId);
    return order ? order.count : 0;
  };

  const onCancel = () => navigate(-1);
  const onNext = () => navigate(`${routeNames.ticketDetails}/${eventId}`);

  if (isLoading) return <div>Loading tickets...</div>;
  if (isError) return <div>Error fetching tickets.</div>;

  return (
    <Container>
      {tickets?.map(ticket => (
        <SelectTicketContainer key={ticket.id}>
          <TicketType>
            <MediumText>{ticket.type}</MediumText>{" "}
          </TicketType>
          <TicketQty>
            <Left>
              <SmallText>{ticket.description}</SmallText>
            </Left>
            <Right>
              <Decrement onClick={() => decrement(ticket.id)}>-</Decrement>
              <div>{getTicketCount(ticket.id)}</div>
              {/* <Increment onClick={() => increment(ticket.id, ticket.price)}>+</Increment> */}
              <Increment onClick={() => increment(ticket.id, ticket.price, ticket.type)}>+</Increment>
            </Right>
          </TicketQty>
          <TicketAmount>
            <SmallText>NGN</SmallText>
            <BoldText>{ticket.price}</BoldText>
          </TicketAmount>
        </SelectTicketContainer>
      ))}
      <Bottom>
        <Cancel onClick={onCancel}>Cancel</Cancel>
        <Continue onClick={onNext}>Continue</Continue>
      </Bottom>
    </Container>
  );
};

export default SelectTicket;
