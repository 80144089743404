import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { ReactComponent as Reset } from "images/filter/reset.svg";
import { ReactComponent as TicketIcon } from "images/organizerevents/Icon.svg";
import { ReactComponent as Create } from "images/organizerevents/Vectoradd.svg";
import { ReactComponent as Totalevent } from "images/organizerevents/Icontotalevents.svg";
import { ReactComponent as Eventconcluded } from "images/organizerevents/Vectoreventsconcluded.svg";
import { ReactComponent as Upcomingevents } from "images/organizerevents/Iconupcomingevents.svg";
import { ReactComponent as Totalrevenue } from "images/organizerevents/Frametotalrevenue.svg";
import { ReactComponent as Hide } from "images/organizerevents/Framehide.svg";
import SearchBar from "ui/components/Dashboard/Filter/Searchbar";
import NoEvent from "./noevent";
import { routeNames } from "lib/routes";
import { Link } from "react-router-dom";
import { useGetOrganizerEvents } from "lib/hooks";
import EventCard from "./eventCard";
import { Pagination } from "antd";
import { useGetOrganizerOverview } from "lib/hooks";
import { getSession } from "lib/utils/utils";

const EventsContainer = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
`;

const TopLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -1.4rem;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 2rem;
    align-items: start;
    justify-content: start;
    gap: 1rem;
    width: 100%;
  }
`;

const Header = styled.h2`
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  margin-top: 0;
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
  }
`;

const EventsInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
    gap: 1rem;
  }
`;

const TotalEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Cardline1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;
const Cardline2 = styled.p`
  font-size: 20px;
  font-weight: 500;
`;
const Cardline3 = styled.p`
  font-size: 12px;
  color: #595959;
`;

const EventsConcluded = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const UpcomingEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TotalRevenue = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SmallText = styled.p`
  font-size: 12px;
`;

const Totalamount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const FilterLine = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const Left = styled.div`
  width: 52%;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const ResetText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;
const CreateEvent = styled(Link)`
  all: unset;
  cursor: pointer;
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 9rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EventsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Bottom = styled.div``;

const EventsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: #f9faff;
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 2rem;
`;

const EventName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  width: 34%;
  padding-left: 16px;
`;
const TicketSold = styled.div`
  /* display: flex;
  flex-direction: row; */
  width: 14%;
`;
const Attendees = styled.div`
  width: 14%;
`;
const Revenue = styled.div`
  width: 14%;
`;
const Status = styled.div`
  width: 14%;
`;

const EditEvent = styled.div`
  width: 1.5%;
`;

const HeaderText = styled.p`
  margin: 0;
  font-size: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #2f2f2f;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const EventsPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [pageSize, setPageSize] = useState(10); // Items per page
  const [organizeremail, setOrganizerEmail] = useState<string>("");
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    // Add logic to perform search with the query
  };

  const payload = {
    page: currentPage,
    limit: pageSize,
    searchstring: searchQuery, // Include search query in payload
  };

  const { data, isLoading, isError, error, refetch } = useGetOrganizerEvents(payload);
  const {
    data: overview,
    isLoading: overviewLoading,
    isError: overviewError,
    error: isoverviewerror,
  } = useGetOrganizerOverview();
  const handlePageChange = (page: number) => {
    setCurrentPage(page); // Update current page
    refetch(); // Refetch data for the new page
  };

  // Calculate the starting number for the current page
  const startingNumber = (currentPage - 1) * pageSize + 1;

  useEffect(() => {
    const session = getSession();
    if (session) {
      setOrganizerEmail(session.email);
    }
  }, []);

  return (
    <EventsContainer>
      <TopLine>
        <Header>Events</Header>
        <CreateEvent to={routeNames.newEvent}>
          <Create />
          create event
        </CreateEvent>
      </TopLine>
      <EventsInfo>
        <TotalEvents>
          <Cardline1>
            <Totalevent />
            Total events created
          </Cardline1>
          <Cardline2>{overview?.totalEventsCreated}</Cardline2>
          <Cardline3>0 created this month</Cardline3>
        </TotalEvents>
        <EventsConcluded>
          <Cardline1>
            <Eventconcluded />
            Events concluded
          </Cardline1>
          <Cardline2>{overview?.eventsConcluded}</Cardline2>
          <Cardline3>0 concluded this month</Cardline3>
        </EventsConcluded>
        <UpcomingEvents>
          <Cardline1>
            <Upcomingevents />
            Upcoming events
          </Cardline1>
          <Cardline2>{overview?.upcomingEvents}</Cardline2>
          <Cardline3>0 upcoming this month</Cardline3>
        </UpcomingEvents>
        <TotalRevenue>
          <Cardline1>
            <Totalrevenue />
            Total revenue
            <Hide style={{ marginLeft: "1rem" }} />
          </Cardline1>
          <Totalamount>
            <SmallText>NGN</SmallText>
            <Cardline2>{overview?.totalRevenue}</Cardline2>
          </Totalamount>
          <Cardline3>NGN 0 made this month</Cardline3>
        </TotalRevenue>
      </EventsInfo>
      <FilterLine>
        <Left>
          {" "}
          <SearchBar onSearch={handleSearch} />
        </Left>
        <Right>
          <Button style={{ fontSize: "13px" }} disabled>
            <Space>
              Event status
              <DownOutlined />
            </Space>
          </Button>
          <Button style={{ fontSize: "13px" }} disabled>
            <Space>
              Event date
              <DownOutlined />
            </Space>
          </Button>
          <Button style={{ fontSize: "13px" }} type="default" disabled>
            <Space>
              <Reset />
              <ResetText>Reset filters</ResetText>
            </Space>
          </Button>
        </Right>
      </FilterLine>

      <Bottom>
        <EventsHeader>
          <EventName>
            <HeaderText>#</HeaderText>
            <HeaderText>Event Name</HeaderText>
          </EventName>
          <TicketSold>
            <HeaderText>
              Tickets Sold
              <TicketIcon />
            </HeaderText>
          </TicketSold>
          <Attendees>
            <HeaderText>Attendees</HeaderText>
          </Attendees>
          <Revenue>
            <HeaderText>Revenue Generated</HeaderText>
          </Revenue>
          <Status>
            <HeaderText>Event Status</HeaderText>
          </Status>
          <EditEvent></EditEvent>
        </EventsHeader>
        {isLoading ? (
          <p>Loading...</p>
        ) : isError || !data?.data?.length ? (
          <NoEvent />
        ) : (
          <EventsCardContainer>
            {data.data.map((event, index) => (
              <EventCard
                key={event.id}
                event={event}
                number={startingNumber + index} // Add startingNumber to index
              />
            ))}
          </EventsCardContainer>
        )}

        {/* Add Pagination */}
        {!isLoading && !isError && (
          <PaginationContainer>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={data?.totalCount || 0} // Use nullish coalescing to provide a default value
              onChange={handlePageChange} // Handle page change
              size="small"
            />
          </PaginationContainer>
        )}
      </Bottom>
    </EventsContainer>
  );
};

export default EventsPage;
