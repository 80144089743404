import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { Checkbox, Select, Button, Form, Tag, DatePicker, TimePicker, Row, Col, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import SubmitButton from "../../profile/Button";
import { useEditEventById } from "lib/hooks";
import { EditEventPayload } from "lib/hooks";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { ReactComponent as SelectImg } from "images/modal/Frameselect.svg";
import { getSession } from "lib/utils/utils";
import { useGetUser } from "lib/hooks";
import { getTimeZones } from "@vvo/tzdb";
import { format, parseISO, isValid } from "date-fns";
import { TZDate } from "@date-fns/tz";
import dayjs from "dayjs";
interface CollapsibleProps {
  isVisible: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CollapseButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  border-radius: 4px;
  background-color: #f8f8f8;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CollapsibleContent = styled.div<CollapsibleProps>`
  max-height: ${props => (props.isVisible ? "700px" : "0")};
  overflow: hidden;
  margin-left: 1rem;
  transition: max-height 0.3s ease;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 1.1rem;
  margin-bottom: 20px;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const OccurrenceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const OccurrenceCard = styled.div<{ disabled?: boolean; isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 45%;
  height: 78px;
  border: 1px solid ${props => (props.disabled ? "#d9d9d9" : props.isSelected ? "rgba(28, 91, 135, 1)" : "#1890ff")};
  border-radius: 8px;
  background-color: ${props => (props.disabled ? "#fafafa" : "#fff")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.7 : 1)};
`;

const OccurrenceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const OccurrenceLabel = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 4px;
  gap: 20px;
`;

const OccurrenceDescription = styled.span`
  font-size: 0.8rem;
  color: #888;
`;
const TimezoneContainer = styled.div`
  margin-bottom: 30px;
`;

const TimezoneLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: end;
  gap: 30px;
`;

const GhostButton = styled(Button)`
  width: 127px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  background-color: #fff;
`;

const ButtonWrapper = styled.div`
  width: 205px;
`;

const DateRow = styled(Row)`
  margin-bottom: 20px;
`;

const DateCol = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const EventTypeCard = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 46%;
  height: 60px;
  border: 1px solid ${props => (props.selected ? "#1890ff" : "#d9d9d9")};
  border-radius: 8px;
  background-color: ${props => (props.disabled ? "#fafafa" : "#fff")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.7 : 1)};

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const EventTypeCardDisabled = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 46%;
  height: 60px;
  border: 1px solid ${props => (props.selected ? "#1C5B87" : "#d9d9d9")};
  border-radius: 8px;
  background-color: ${props => (props.disabled ? "#fafafa" : "#fff")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.7 : 1)};

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const Right = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const EventTypeLabel = styled.p<{ selected?: boolean }>`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${({ selected }) => (selected ? "#1C5B87" : "initial")};
`;

const CardDisabled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const EventTypeDescription = styled.p<{ selected?: boolean }>`
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ selected }) => (selected ? "#1C5B87" : "#888")};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const ComingSoonButton = styled.div`
  padding: 2px 5px;
  background-color: #fffefa;
  color: #665000;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  font-size: 9px;
  cursor: not-allowed;
  margin-left: auto;
  margin-right: 0.5rem;
`;
type DateTimeProps = {
  event: any;
  onUpdate: () => void; // Callback to notify parent component
};

const DateTime: React.FC<DateTimeProps> = ({ event, onUpdate }) => {
  const { data: user } = useGetUser();
  const [isVisible, setIsVisible] = useState(false);
  const [selectedType, setSelectedType] = useState<string | null>(null);

  const organizerEmail = useMemo(() => {
    const session = getSession();
    return user?.email || session?.email;
  }, [user]);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const [isSingleEventSelected, setIsSingleEventSelected] = useState(false);
  const [timezones, setTimezones] = useState<string[]>([]);
  const [form] = Form.useForm();
  const [isUpdating, setIsUpdating] = useState(false);

  const { mutate: updateEvent } = useEditEventById();
  const navigate = useNavigate();

  const timeformat = "HH:mm";

  // Fetch timezones for the form
  useEffect(() => {
    const timeZonesWithUtc = getTimeZones({ includeUtc: true });
    setTimezones(timeZonesWithUtc.map(zone => `${zone.name} (GMT ${zone.currentTimeFormat})`));
  }, []);

  const handleCardClick = (type: string) => {
    setSelectedType(prevType => (prevType === type ? null : type));
  };

  const handleSingleEventClick = () => {
    setIsSingleEventSelected(prev => !prev);
  };

  //initial details in form
  useEffect(() => {
    if (event) {
      const { startDate, endDate, timezone } = event;
      setSelectedType(event.occurence);
      if (startDate && endDate) {
        // Set initial form values
        form.setFieldsValue({
          startDate: dayjs(startDate),
          startTime: dayjs(startDate),
          endDate: dayjs(endDate),
          endTime: dayjs(endDate),
          timeZone: timezone || "",
        });
      }
    }
  }, [event, form]);

  // Handle form submission
  const handleSubmit = async (values: any) => {
    try {
      // Extract values from the form
      const { startDate, startTime, endDate, endTime, timeZone } = values;

      // Parse the timezone name from the selected value
      const timezoneName = timeZone.split(" ")[0];

      // Combine the date and time strings
      const startDateTimeString = `${startDate.format("YYYY-MM-DD")}T${startTime.format("HH:mm")}:00`;
      const endDateTimeString = `${endDate.format("YYYY-MM-DD")}T${endTime.format("HH:mm")}:00`;

      // Create TZDate instances
      const startDateTime = new TZDate(startDateTimeString, timezoneName).toISOString();
      const endDateTime = new TZDate(endDateTimeString, timezoneName).toISOString();

      // Construct the payload
      const payload: EditEventPayload = {
        id: event.id,
        data: {
          occurence: selectedType || "single",
          timezone: timezoneName,
          startDate: startDateTime,
          endDate: endDateTime,
        },
      };

      // Update the event
      updateEvent(payload, {
        onSuccess: () => {
          message.success("Event updated successfully");
          onUpdate();
        },
        onError: error => {
          message.error(`Error updating event: ${error.message}`);
        },
      });
    } catch (error) {
      if (error instanceof Error) {
        message.error(`Error processing event: ${error.message}`);
      } else {
        message.error("An unknown error occurred.");
      }
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Container>
      <CollapseButton onClick={toggleVisibility}>
        Date & Time
        {isVisible ? <DownOutlined /> : <RightOutlined />}
      </CollapseButton>

      <CollapsibleContent isVisible={isVisible}>
        <ContentContainer>
          <Top>
            <SmallText>Occurrence</SmallText>
          </Top>

          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark={false}
            initialValues={{
              eventType: isSingleEventSelected ? "single" : "",
              timeZone: "Africa/Lagos (GMT +01:00 West Africa Time - Lagos, Kano, Ibadan, Abuja)", // Set default timezone here
            }}
          >
            {/* Occurrence Section */}
            <Form.Item name="occurence">
              <OccurrenceContainer>
                <EventTypeCard selected={selectedType === "single"} onClick={() => handleCardClick("single")}>
                  <Left>
                    <EventTypeLabel selected={selectedType === "single"}>Single event</EventTypeLabel>
                    <EventTypeDescription selected={selectedType === "single"}>
                      Happens only once at a specified date and time
                    </EventTypeDescription>
                  </Left>
                  <Right visible={selectedType === "single"}>
                    <SelectImg />
                  </Right>
                </EventTypeCard>

                <EventTypeCardDisabled selected={selectedType === "recurring"} disabled>
                  <CardDisabled>
                    <EventTypeLabel> Recurring event</EventTypeLabel>
                    <ComingSoonButton>Coming Soon</ComingSoonButton>
                  </CardDisabled>
                  <EventTypeDescription>Happens repeatedly over a specified period</EventTypeDescription>
                </EventTypeCardDisabled>
              </OccurrenceContainer>
            </Form.Item>

            {/* Timezone Section */}
            <TimezoneContainer>
              <TimezoneLabel>Timezone</TimezoneLabel>
              <Form.Item name="timeZone" rules={[{ required: true, message: "Please select a timezone" }]}>
                <Select showSearch placeholder="Select Timezone" optionFilterProp="children">
                  {timezones.map((name, index) => (
                    <Select.Option key={index} value={name}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </TimezoneContainer>

            {/* Date and Time Section */}
            <DateRow gutter={16}>
              <DateCol span={12}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true, message: "Please select a start date" }]}
                >
                  <DatePicker style={{ width: "100%", height: "56px" }} />
                </Form.Item>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true, message: "Please select an end date" }]}
                >
                  <DatePicker style={{ width: "100%", height: "56px" }} />
                </Form.Item>
              </DateCol>

              <DateCol span={12}>
                <Form.Item
                  label="Start Time"
                  name="startTime"
                  rules={[{ required: true, message: "Please select a start time" }]}
                >
                  <TimePicker style={{ width: "100%", height: "56px" }} format={timeformat} />
                </Form.Item>

                <Form.Item
                  label="End Time"
                  name="endTime"
                  rules={[{ required: true, message: "Please select an end time" }]}
                >
                  <TimePicker style={{ width: "100%", height: "56px" }} format={timeformat} />
                </Form.Item>
              </DateCol>
            </DateRow>

            <SubmitContainer>
              <SubmitButton form={form}>Save changes</SubmitButton>
            </SubmitContainer>
          </Form>
        </ContentContainer>
      </CollapsibleContent>
    </Container>
  );
};

export default DateTime;
