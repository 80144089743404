import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { Form, Input, notification } from "antd";
import { ReactComponent as NotificationImg } from "images/vendor/Alert.svg";
import { Container, Label } from "./general";
import { SubmitContainer } from "../AccountDetails";
import SubmitButton from "../Button";
import { useEditVendorBank } from "lib/hooks/vendor/useVendorBankById";
import { useAddOrganizerBankDetails } from "lib/hooks/organizersettings";
import { AddBankDetailsPayload } from "lib/hooks/organizersettings";
import { useGetUser } from "lib/hooks";

const Alert = styled.div`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  margin-bottom: 20px;
`;

const BankDetails = () => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: user } = useGetUser();
  const { mutate: addBankDetails } = useAddOrganizerBankDetails();

  const handleFinish = async (values: any) => {
    setIsSubmitting(true);
    if (!user) {
      notification.warning({ message: "Fetching user data... kindly try again shortly" });
      return;
    }

    // Prepare the payload for the mutation

    const payload: AddBankDetailsPayload = {
      bankName: values.bankName,
      bankAccountName: values.bankAccountName,
      bankAccountNumber: values.bankAccountNumber,
      bankCode: 0,
      organizerEmail: user.email,
    };

    // Call the mutation
    addBankDetails(payload, {
      onSuccess: response => {
        notification.success({ message: "Bank details added successfully!" });
        console.log("Bank details added:", response);
      },
      onError: error => {
        notification.error({ message: "Failed to add bank details. Please try again." });
        console.error("Error adding bank details:", error);
      },
    });
  };

  return (
    <>
      <Alert>
        <NotificationImg /> <p>Please ensure all details are correct</p>
      </Alert>
      <Container>
        <Form layout="vertical" form={form} onFinish={handleFinish} requiredMark={false}>
          <Form.Item
            label="Bank Name"
            name="bankName"
            rules={[{ required: true, message: "Please enter your bank name" }]}
          >
            <Input
              style={{ width: "100%", fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
              placeholder="Enter your bank name"
            />
          </Form.Item>
          <Form.Item
            label="Account Name"
            name="bankAccountName"
            rules={[{ required: true, message: "Please enter your bank account name" }]}
          >
            <Input
              style={{ width: "100%", fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
              placeholder="Enter your bank account name"
            />
          </Form.Item>
          <Form.Item
            label="Account Number"
            name="bankAccountNumber"
            rules={[{ required: true, message: "Please enter your bank account number" }]}
          >
            <Input
              style={{ width: "100%", fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
              placeholder="Enter your bank account number"
            />
          </Form.Item>

          <Form.Item>
            <SubmitContainer>
              <SubmitButton form={form}>Save Changes</SubmitButton>
            </SubmitContainer>
          </Form.Item>
        </Form>
      </Container>
    </>
  );
};

export default BankDetails;
