import React, { useState, useMemo, useEffect } from "react";
import { Form, Input, Select, message } from "antd";
import styled from "styled-components";
import { RightOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import SubmitButton from "../../profile/Button";
import { useEditEventById, useUploadBanner } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import { useGetUser } from "lib/hooks";
import { useGetEventCategories } from "lib/hooks/eventcategory";
import { useDropzone } from "react-dropzone";

interface CollapsibleProps {
  isVisible: boolean;
}

const { Option } = Select;
const { TextArea } = Input;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

const CollapseButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  border-radius: 4px;
  background-color: #f8f8f8;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CollapsibleContent = styled.div<CollapsibleProps>`
  max-height: ${props => (props.isVisible ? "800px" : "0")};
  margin-left: 1rem;
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

const FormBox = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const EmailInput = styled(Input)`
  background-color: #d9d9d9;
  color: #9c9c9c;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed #1390e6;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #f9faff;
  &:hover {
    border-color: #0d6efd;
  }
`;

const ImagePreviewContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 1rem;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 200px;
  border-radius: 8px;
`;

const EditIconWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type EventDetailsProps = {
  event: any;
  onUpdate: () => void; // Callback to notify parent component
};

const EventDetails: React.FC<EventDetailsProps> = ({ event, onUpdate }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();
  const { data: user } = useGetUser();
  const { data: categories, isLoading: isCategoriesLoading, error: categoriesError } = useGetEventCategories();
  const organizerEmail = useMemo(() => {
    const session = getSession();
    return user?.email || session?.email;
  }, [user]);

  const [uploadedFile, setUploadedFile] = useState<File | null>(null); // State to store the uploaded file
  const [showDropzone, setShowDropzone] = useState(!event?.banner); // Show dropzone if no banner exists initially

  const { mutate: editEvent } = useEditEventById();
  const { mutate: uploadBanner } = useUploadBanner(); // Use the uploadBanner hook

  useEffect(() => {
    if (event) {
      form.setFieldsValue({
        eventtitle: event.title,
        organizer: event.organizerEmail,
        category: event.category,
        aboutevent: event.about,
      });

      // If the event has a banner, hide the dropzone initially
      if (event.banner) {
        setShowDropzone(false);
      }
    }
  }, [event, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (event !== null) {
        // First, upload the banner if a new file is uploaded
        if (uploadedFile) {
          const bannerPayload = {
            eventTitle: values.eventtitle.trim(),
            banner: uploadedFile,
          };

          await uploadBanner(
            { eventId: event.id, payload: bannerPayload },
            {
              onSuccess: () => {
                message.success("Banner uploaded successfully");
                onUpdate(); // Notify parent component that the event is updated
              },
              onError: error => {
                console.error("Error uploading banner:", error);
                message.error("Failed to upload banner");
              },
            },
          );
        }

        // Then, update the event details
        editEvent(
          {
            id: event.id,
            data: {
              title: values.eventtitle,
              organizerEmail: organizerEmail,
              category: values.category,
              about: values.aboutevent,
            },
          },
          {
            onSuccess: () => {
              message.success("Event updated");
              onUpdate(); // Notify parent component that the event is updated
            },
            onError: () => message.error("Failed to update the event."),
          },
        );
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // React Dropzone configuration
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxFiles: 1,
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];
      if (file) {
        setUploadedFile(file);
        setShowDropzone(false); // Hide dropzone and show image after upload
      }
    },
  });

  // Handle edit icon click
  const handleEditClick = () => {
    setShowDropzone(true); // Show dropzone to change the image
  };

  return (
    <Container>
      <CollapseButton onClick={toggleVisibility}>
        Event Details
        {isVisible ? <DownOutlined /> : <RightOutlined />}
      </CollapseButton>

      <CollapsibleContent isVisible={isVisible}>
        <EventContainer>
          <Top>
            <SmallText>Event Banner</SmallText>
            {showDropzone ? (
              <DropzoneContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <SmallText>
                  <span style={{ color: "#1390e6" }}>Click to upload</span> or drag and drop
                </SmallText>
                <SmallText>PNG, JPG (max. 800x400px)</SmallText>
              </DropzoneContainer>
            ) : (
              <ImagePreviewContainer>
                <ImagePreview
                  src={uploadedFile ? URL.createObjectURL(uploadedFile) : event?.banner}
                  alt="Event Banner"
                />
                <EditIconWrapper onClick={handleEditClick}>
                  <EditOutlined />
                </EditIconWrapper>
              </ImagePreviewContainer>
            )}
          </Top>

          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            initialValues={{ remember: true }}
            requiredMark={false}
          >
            <FormBox style={{ flex: 1 }}>
              <Form.Item label="Event Title" name="eventtitle" rules={[{ required: true, message: "" }]}>
                <Input style={{ width: "97%", fontSize: "0.8rem" }} />
              </Form.Item>
            </FormBox>

            <FormBox style={{ flex: 1 }}>
              <Form.Item label="organizer" name="organizer" rules={[{ required: true, message: "" }]}>
                <Input style={{ width: "97%", fontSize: "0.8rem" }} />
              </Form.Item>
            </FormBox>

            <FormBox style={{ flex: 1 }}>
              <Form.Item
                label="Event Category"
                name="category"
                rules={[{ required: true, message: "Please select a category" }]}
              >
                <Select
                  placeholder="Select category"
                  showSearch
                  loading={isCategoriesLoading}
                  style={{ width: "97%" }}
                  filterOption={(input, option) =>
                    typeof option?.children === "string"
                      ? String(option.children).toLowerCase().includes(input.toLowerCase())
                      : false
                  }
                >
                  {categoriesError && <Option disabled>Error fetching categories</Option>}
                  {categories?.map(category => (
                    <Option key={category.id} value={category.name}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </FormBox>

            <FormBox style={{ flex: 1 }}>
              <Form.Item label="About Event" name="aboutevent" rules={[{ required: true, message: "" }]}>
                <TextArea rows={4} style={{ width: "97%", fontSize: "0.8rem" }} />
              </Form.Item>
            </FormBox>

            <SubmitContainer>
              <SubmitButton form={form}>Save changes</SubmitButton>
            </SubmitContainer>
          </Form>
        </EventContainer>
      </CollapsibleContent>
    </Container>
  );
};

export default EventDetails;
