import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Check } from "images/vendor/Check.svg";
import { ReactComponent as Check2 } from "images/vendor/Vector2.svg";
import { ReactComponent as Content } from "images/organizerhome/Content.svg";
import { ReactComponent as Plant } from "images/vendor/Plant.svg";
import { ReactComponent as Plant2 } from "images/organizerhome/Plant.svg";
import { ReactComponent as Message } from "images/organizerhome/Messages.svg";
import { useGetUsers, useGetOrganizerEvents } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import SearchBar from "ui/components/Dashboard/Filter/Searchbar";
import { routeNames } from "lib/routes";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Totalevent } from "images/organizerevents/Icontotalevents.svg";
import { ReactComponent as Eventconcluded } from "images/organizerevents/Vectoreventsconcluded.svg";
import { ReactComponent as Upcomingevents } from "images/organizerevents/Iconupcomingevents.svg";
import { ReactComponent as Totalrevenue } from "images/organizerevents/Frametotalrevenue.svg";
import { ReactComponent as Hide } from "images/organizerevents/Framehide.svg";
import { useGetOrganizerOverview } from "lib/hooks";

const Container = styled.div`
  margin: 1rem;
`;

const Top = styled.div`
  margin-top: -1.4rem;
  @media (max-width: 768px) {
    margin-top: 3rem;
  }
`;

const Header = styled.h3`
  font-size: 32px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
  }
`;

const Second = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  gap: 0.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    width: 90%;
  }
`;

const Third = styled.div`
  background-color: #f9faff;
  border-radius: 12px;
  margin-top: 1rem;
  padding: 1rem;
  height: 100vh;
`;

const Cardline3 = styled.p`
  font-size: 12px;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
`;

const Left = styled.div`
  background-color: #f9faff;
  width: 65%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 12px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Leftside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Largetext = styled.p`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const Rightside = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Right = styled.div`
  background-color: #f9faff;
  width: 35%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 12px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Line1 = styled.div``;

const Line2 = styled.div``;

const Line3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Line4 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const LogoContainer1 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LogoContainer2 = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const Rightdiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const Bluetext = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #1c5b87;
  margin-top: 0;
  margin-bottom: 0;
`;

const Addnew = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 18rem;
  cursor: pointer;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SmallTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  margin-top: 0;
  margin-bottom: 0;
`;

const MediumText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;

  margin-top: 0;
  margin-bottom: 0;
`;

const SearchBarContainer = styled.div`
  width: 60%;
  background-color: white;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EventsInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
    gap: 1rem;
  }
`;

const TotalEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Cardline1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;
const Cardline2 = styled.p`
  font-size: 20px;
  font-weight: 500;
`;
const Cardline4 = styled.p`
  font-size: 12px;
  color: #595959;
`;

const EventsConcluded = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const UpcomingEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TotalRevenue = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SmallText = styled.p`
  font-size: 12px;
`;

const Totalamount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const OrganizerHome: React.FC = () => {
  const [userId, setUserId] = useState<number | null>(null);
  const [name, setName] = useState<string>("");
  const [organizeremail, setOrganizerEmail] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const payload = {
    page: 1,
    limit: 10,
  };

  const { data, isLoading, isError, error, refetch } = useGetOrganizerEvents(payload);
  const {
    data: overview,
    isLoading: overviewLoading,
    isError: overviewError,
    error: isoverviewerror,
  } = useGetOrganizerOverview();
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    // Add logic to perform search with the query
  };

  const { data: usersData, error: usersError } = useGetUsers();

  useEffect(() => {
    const session = getSession();
    if (session) {
      setUserId(session.userId);
      setOrganizerEmail(session.email);
    }
  }, []);

  useEffect(() => {
    if (userId && usersData) {
      const user = usersData.find(user => user.id === userId);
      if (user) {
        setName(capitalizeFirstLetter(user.firstName));
      }
    }
    if (usersError) {
      console.error("Error fetching users data:", usersError);
    }
  }, [userId, usersData, usersError]);

  const handleCreateEvent = () => {
    navigate(routeNames.newEvent);
  };

  // Check if there are any events
  const hasEvents = (data?.data?.length ?? 0) > 0;

  return (
    <Container>
      <Top>
        <Header>Welcome, {name}!</Header>
      </Top>

      {/* Show Second div if there are no events */}
      {!hasEvents && (
        <Second>
          <Left>
            <Leftside>
              <Largetext>No events created yet</Largetext>
              <Cardline3>
                Streamline event setup: add details, tickets, and vendors <br />
                for seamless, engaging experiences.
              </Cardline3>
              <Addnew onClick={handleCreateEvent}>Try creating an event</Addnew>
            </Leftside>
            <Rightside>
              <Content />
            </Rightside>
            <LogoContainer1>
              <Plant />
            </LogoContainer1>
            <LogoContainer2>
              <Plant2 />
            </LogoContainer2>
          </Left>
          <Right>
            <Line1>
              {" "}
              <Largetext>To-do</Largetext>
            </Line1>
            <Line2>
              <Cardline3>Begin your success journey with our user-friendly guide</Cardline3>
            </Line2>
            <Line3>
              <Check2 />
              <Rightdiv>
                <Bluetext>Finish setting up profile</Bluetext>
                <Cardline3>Title, profile image, business name, etc</Cardline3>
              </Rightdiv>
            </Line3>
            <Line4>
              <Check />
              <Rightdiv>
                <Bluetext>Create an event</Bluetext>
                <Cardline3>Create, publish & manage all events in one place</Cardline3>
              </Rightdiv>
            </Line4>
          </Right>
        </Second>
      )}

      {/* Show EventsInfo div if there are events */}
      {hasEvents && (
        <EventsInfo>
          <TotalEvents>
            <Cardline1>
              <Totalevent />
              Current balance
            </Cardline1>
            <Cardline2>{overview?.currentBalance}</Cardline2>
            <Cardline3>NGN 0 increase this month</Cardline3>
          </TotalEvents>
          <EventsConcluded>
            <Cardline1>
              <Eventconcluded />
              Total events created
            </Cardline1>
            <Cardline2>{overview?.totalEventsCreated}</Cardline2>
            <Cardline3>0 concluded this month</Cardline3>
          </EventsConcluded>
          <UpcomingEvents>
            <Cardline1>
              <Upcomingevents />
              Upcoming events
            </Cardline1>
            <Cardline2>{overview?.upcomingEvents}</Cardline2>
            <Cardline4>0 upcoming this month</Cardline4>
          </UpcomingEvents>
          <TotalRevenue>
            <Cardline1>
              <Totalrevenue />
              Total expenses
              <Hide style={{ marginLeft: "1rem" }} />
            </Cardline1>
            <Totalamount>
              <SmallText>NGN</SmallText>
              <Cardline2>{overview?.totalExpenses}</Cardline2>
            </Totalamount>
            <Cardline3>NGN 0 made this month</Cardline3>
          </TotalRevenue>
        </EventsInfo>
      )}

      <Third>
        <TopSection>
          <SmallTitle>Recent transactions</SmallTitle>
          <SearchBarContainer>
            <SearchBar onSearch={handleSearch} />
          </SearchBarContainer>
        </TopSection>
        <BottomSection>
          <Message />
          <MediumText>No transactions yet</MediumText>
        </BottomSection>
      </Third>
    </Container>
  );
};

export default OrganizerHome;
