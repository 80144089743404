import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Totalevent } from "images/organizerevents/Icontotalevents.svg";
import { ReactComponent as Eventconcluded } from "images/organizerevents/Vectoreventsconcluded.svg";
import { ReactComponent as Upcomingevents } from "images/organizerevents/Iconupcomingevents.svg";
import { ReactComponent as Totalrevenue } from "images/organizerevents/Frametotalrevenue.svg";
import { ReactComponent as Hide } from "images/organizerevents/Framehide.svg";
import { ReactComponent as Logo } from "images/vendor/Logomark.svg";
import { ReactComponent as Step } from "images/vendor/stepping_up.svg";
import { ReactComponent as Right11 } from "images/vendor/Plant.svg";
import EventImg from "images/organizerevents/Rectangle3294.png";
import { ReactComponent as Calender } from "images/organizerevents/Frame.svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { Select } from "antd";
const { Option } = Select;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const Section1 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 0.5rem;
  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    flex-direction: column;
  }
`;

const Section1Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 59%;
`;

const BoldText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  color: #121212;
`;

const Mediumlight = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #595959;
`;

const MediumBold = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #121212;
`;

const Section2 = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }
`;

const Section3 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Section4 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    flex-direction: column;
  }
`;

const EventsInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
`;

const TotalEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Cardline1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;
const Cardline2 = styled.p`
  font-size: 20px;
  font-weight: 500;
`;
const Cardline3 = styled.p`
  font-size: 12px;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const Cardline4 = styled.p`
  font-size: 12px;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  margin-bottom: 2rem;
`;

const LogoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const EventsConcluded = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const UpcomingEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TotalRevenue = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SmallText = styled.p`
  font-size: 12px;
`;

const Totalamount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Left1 = styled.div`
  background-color: #f9faff;
  width: 60%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Right1 = styled.div`
  background-color: #f9faff;
  width: 40%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Leftside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Rightside2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Largetext = styled.p`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const Rightside = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Marketing = styled.button`
  background-color: #fffefa;
  color: #1390e6;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 10px;
  margin-left: 0.5rem;
  width: 18rem;
  margin-top: 1rem;
  cursor: pointer;
`;

const LogoContainer3 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Chart1 = styled.div`
  background-color: #f9fbff;
  width: 70%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  @media (max-width: 768px) {
    width: calc(100% - 1rem);
  }
`;

const Chart1Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const Chart2 = styled.div`
  background-color: #f9fbff;
  width: 30%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  padding: 0.5rem;
  @media (max-width: 768px) {
    width: calc(100% - 1rem);
  }
`;

const Chart2Header = styled.div``;

const Guide = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const GuideItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;
const DataDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #9823e0; /* Change to your desired color */
  border-radius: 50%; /* Makes the element a circle */
  display: inline-block; /* Keeps the dot inline with other elements */
`;

const HighestDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #168f43; /* Change to your desired color */
  border-radius: 50%; /* Makes the element a circle */
  display: inline-block; /* Keeps the dot inline with other elements */
`;

const LowestDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #dd96ff; /* Change to your desired color */
  border-radius: 50%; /* Makes the element a circle */
  display: inline-block; /* Keeps the dot inline with other elements */
`;

const NoDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #eaeaea; /* Change to your desired color */
  border-radius: 50%; /* Makes the element a circle */
  display: inline-block; /* Keeps the dot inline with other elements */
`;
const data = [
  {
    name: "Jan",
    uv: 400,
    pv: 240,
  },
  {
    name: "Feb",
    uv: 300,
    pv: 139,
  },
  {
    name: "Mar",
    uv: 200,
    pv: 980,
  },
  {
    name: "Apr",
    uv: 278,
    pv: 390,
  },
  {
    name: "May",
    uv: 189,
    pv: 480,
  },
  {
    name: "Jun",
    uv: 239,
    pv: 380,
  },
  {
    name: "Jul",
    uv: 349,
    pv: 430,
  },
  {
    name: "Aug",
    uv: 349,
    pv: 430,
  },
  {
    name: "Sep",
    uv: 349,
    pv: 430,
  },
  {
    name: "Oct",
    uv: 349,
    pv: 430,
  },
  {
    name: "Nov",
    uv: 349,
    pv: 430,
  },
  {
    name: "Dec",
    uv: 349,
    pv: 430,
  },
];

//piechart
const piedata = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

type DashboardProps = {
  event: any;
};
const Dashboard: React.FC<DashboardProps> = ({ event }) => {
  const formattedDate = event?.startDate
    ? new Date(event.startDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
    : "Date Unavailable";
  return (
    <Container>
      <Section1>
        <img src={event?.banner} width="216px" height="116px" style={{ borderRadius: "8px" }} />
        <Section1Details>
          <BoldText>{event?.title || "Event Title"}</BoldText>
          <Mediumlight>{event?.about || "Event Description"}</Mediumlight>
          <Cardline3>
            <Calender /> {formattedDate}{" "}
          </Cardline3>
        </Section1Details>
      </Section1>
      <Section2>
        <EventsInfo>
          <TotalEvents>
            <Cardline1>
              <Totalevent />
              Event status
            </Cardline1>
            <Cardline2>Draft</Cardline2>
            <Cardline3>Created on 12 Dec, 2023</Cardline3>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </TotalEvents>
          <EventsConcluded>
            <Cardline1>
              <Eventconcluded />
              Tickets sold
            </Cardline1>
            <Cardline2>0</Cardline2>
            <Cardline3>0 sold this month</Cardline3>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </EventsConcluded>
          <UpcomingEvents>
            <Cardline1>
              <Upcomingevents />
              Page views
            </Cardline1>
            <Cardline2>0</Cardline2>
            <Cardline3>0 views this month</Cardline3>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </UpcomingEvents>
          <TotalRevenue>
            <Cardline1>
              <Totalrevenue />
              Total revenue
              <Hide style={{ marginLeft: "1rem" }} />
            </Cardline1>
            <Totalamount>
              <SmallText>NGN</SmallText>
              <Cardline2>0.00</Cardline2>
            </Totalamount>
            <Cardline3>NGN 0 made this month</Cardline3>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </TotalRevenue>
        </EventsInfo>
      </Section2>
      <Section3>
        <Chart1>
          <Chart1Header>
            <MediumBold>Ticket sales by numbers</MediumBold>
            <Guide>
              <GuideItem>
                <DataDot />
                <Cardline3>Data</Cardline3>
              </GuideItem>
              <GuideItem>
                <HighestDot />
                <Cardline3>Highest</Cardline3>
              </GuideItem>
              <GuideItem>
                <LowestDot />
                <Cardline3>Lowest</Cardline3>
              </GuideItem>
              <GuideItem>
                <NoDot />
                <Cardline3>No Data</Cardline3>
              </GuideItem>
            </Guide>

            <Select
              defaultValue="2024"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                { value: "2024", label: "2024" },
                { value: "2023", label: "2023" },
                { value: "2022", label: "2022" },
              ]}
            />
          </Chart1Header>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data} margin={{ top: 40, right: 10, left: 0, bottom: 0 }}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" padding={{ left: 30, right: 30 }} tickSize={0} tickMargin={10} />
              <YAxis axisLine={false} tickSize={0} tickMargin={10} />
              <Tooltip />

              <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </Chart1>
        <Chart2>
          <Chart2Header>
            {" "}
            <MediumBold>Sales by ticket type</MediumBold>
          </Chart2Header>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={piedata}
                cx={150}
                cy={150}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={90}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Chart2>
      </Section3>
      <Section4>
        <Left1>
          <Leftside>
            <Largetext>Want to increase sales?</Largetext>
            <Cardline3>
              Elevate sales through diverse marketing strategies and
              <br /> campaigns for an enhanced business experience
            </Cardline3>
            <Marketing>See marketing tools</Marketing>
          </Leftside>
          <Rightside>
            <Step />
          </Rightside>
          <LogoContainer3>
            <Right11 />
          </LogoContainer3>
        </Left1>
        <Right1>
          <Rightside2>
            <Largetext>Need funds for your event?</Largetext>
            <Cardline3>
              Let us assist you! Request a loan today and turn your event
              <br /> vision into a memorable reality.
            </Cardline3>
            <Marketing>Request for a loan</Marketing>
          </Rightside2>
        </Right1>
      </Section4>
    </Container>
  );
};

export default Dashboard;
