import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form } from "antd";
import { useTicketContext } from "lib/context/ticketpurchase/ticketContext";
import { useInitiateTicket } from "lib/hooks/ticketpurchase";
import { InitiateTicketPayload } from "lib/hooks/ticketpurchase";
import { useNavigate, useParams } from "react-router-dom";
import { useTicketPurchaseComplete } from "lib/hooks/ticketpurchase";
// import { usePaystackPayment } from "react-paystack";
import PaystackPop from "@paystack/inline-js"; // use official js lib
import { routeNames } from "lib/routes";
import PaystackInline from "types/paystack-inline";
import { CloseCircleOutlined } from "@ant-design/icons";

const Container = styled.div`
  max-width: 100%;
  padding-right: 0.5rem;
`;
const InitializePayment = styled.div``;

const PaymentVerifying = styled.div`
  display: flex;
  flex-direction: column;
`;

const Waiting = styled.button`
  background-color: #f4f4f4;
  color: #121212;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 3%;
  text-align: center;
  border-radius: 8px;
  padding: 5px 5px;
  width: 8rem;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const BoldText = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  color: #121212;
`;

const SmallText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0%;
  color: #595959;
`;

const MediumText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0%;

  color: #2f2f2f;
`;

const VerifyingTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 3rem;
  text-align: center;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 2rem auto;
  position: relative;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

const Circle = styled.div<{ completed?: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => (props.completed ? "#168F43" : "#FFFFFF")};
  border: 2px solid ${props => (props.completed ? "#168F43" : "#CCCCCC")};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    content: "✓";
    color: white;
    font-size: 14px;
    display: ${props => (props.completed ? "block" : "none")};
  }
`;

const Line = styled.div<{ completed?: boolean }>`
  position: absolute;
  height: 1px;
  background-color: ${props => (props.completed ? "#168F43" : "#CCCCCC")};
  top: 50%;
  left: 15%; // Adjust based on your layout
  right: 15%; // Adjust based on your layout
  transform: translateY(-50%);
  z-index: 0;
`;

const Line1 = styled(Line)`
  left: 17%; // Adjust based on your layout
  right: 50%;
  margin-top: -0.7rem;
`;

const Line2 = styled(Line)`
  left: 50%; // Adjust based on your layout
  right: 15%;
  margin-top: -0.7rem;
`;

const StepLabel = styled.span<{ completed?: boolean }>`
  margin-top: 8px;
  font-size: 12px;
  color: ${props => (props.completed ? "#168F43" : "#666666")};
  text-align: center;
`;

const VerifyingCenter = styled.div``;

const VerifyingBottom = styled.div`
  margin-top: 5rem;
  text-align: center;
`;

const PaymentSuccess = styled.div``;

const PaymentFailed = styled.div``;

const FailureIcon = styled(CloseCircleOutlined)`
  font-size: 48px;
  color: #ff4d4f; // Red color for failure
`;

const FailureText = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #121212;
  margin: 0;
`;

const Price = styled.div``;

const Pay = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Content = styled.div``;

interface PaystackReference {
  reference: string;
  transaction: string;
  status: string;
}

const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

const Checkout: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);
  const { ticketData, currentView, updateCurrentView } = useTicketContext();
  const { mutate: initiateTicket } = useInitiateTicket();
  const params = useParams<{ id?: string }>();
  const [eventId, setEventId] = useState<number>(0);
  const [shouldRetry, setShouldRetry] = useState(true);
  const { mutate: ticketPurchaseComplete } = useTicketPurchaseComplete();
  const [sessionid, setSessionId] = useState("");
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : undefined;
    if (id) setEventId(id);
  }, [params.id]);

  const onFieldsChange = () => {
    const isValid = form.getFieldsError().every(field => field.errors.length === 0);
    setIsFormValid(isValid);
  };

  const handlePayClick = () => {
    setIsLoading(true); // Disable the button and show loading state

    const payload: InitiateTicketPayload = {
      eventId: eventId,
      attendee: ticketData.attendee || { firstName: "", lastName: "", email: "", phone: "" },
      orders: ticketData.orders.map(order => ({
        eventTicketId: order.eventTicketId,
        count: order.count,
      })),
    };

    initiateTicket(payload, {
      onSuccess: data => {
        console.log("Total Price:", data.totalPrice);

        if (data.totalPrice === 0) {
          const completePayload = {
            sessionId: data.sessionId,
            transactionId: data.reference,
          };

          ticketPurchaseComplete(completePayload, {
            onSuccess: response => {
              console.log("Purchase complete:", response);
              updateCurrentView("success");
              setIsLoading(false); // Reset loading state
            },
            onError: error => {
              console.error("Error completing purchase:", error);

              // Check if the error message contains the status code 409
              if (error.message.includes("Request failed with status code 409")) {
                updateCurrentView("success"); // Update view on 409 error
              }

              setIsLoading(false); // Reset loading state
            },
          });
        } else {
          const config = {
            reference: data.reference,
            email: data.attendee.email,
            amount: data.totalPrice * 100, // Convert to kobo
            publicKey,
          };

          const paystackSuccess = () => {
            console.log("Payment successful:");
            updateCurrentView("verify");
            setSessionId(data.sessionId);
            navigate(`${routeNames.ticketCheckout}/${eventId}`);
          };
          const paystackError = (err: any) => {
            updateCurrentView("failed");
            console.log({ err });
          };
          const paystackClose = () => {
            console.log("Payment dialog closed");
            setIsLoading(false); // Reset loading state
          };

          const popup: PaystackInline = new PaystackPop() as never;

          const transaction: any = popup.resumeTransaction(data.accessCode);
          transaction.callbacks.onSuccess = paystackSuccess;
          transaction.callbacks.onError = paystackError;
          console.log({ transaction, accessCode: data.accessCode });
          // paystackSuccess();
        }
      },
      onError: (error: any) => {
        console.log("Error during ticket initiation:", error);
      },
    });
  };

  const renderContent = () => {
    switch (currentView) {
      case "price":
        return (
          <Price>
            <Form layout="vertical" form={form} name="register">
              <Form.Item>
                <Pay onClick={handlePayClick} disabled={isLoading}>
                  {isLoading ? "Processing..." : `Pay NGN ${ticketData.totalAmount}`}
                </Pay>
              </Form.Item>
            </Form>
          </Price>
        );
      case "verify":
        return (
          <PaymentVerifying>
            <VerifyingTop>
              <Waiting>
                Waiting time: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
              </Waiting>
              <BoldText>Please wait while we confirm your transaction</BoldText>
              <SmallText>
                This process may exceed expected time. Feel free to copy the link below, leave
                <br />
                and return later to refresh this page.
              </SmallText>
            </VerifyingTop>
            <VerifyingCenter>
              <StepContainer>
                {/* Line from first circle to second circle */}
                <Line1 completed={true} />
                {/* Line from second circle to third circle */}
                <Line2 completed={false} />
                <Step>
                  <Circle completed={true} />
                  <StepLabel completed={true}>Initiated Transaction</StepLabel>
                </Step>
                <Step>
                  <Circle completed={true} />
                  <StepLabel completed={true}>Amount Sent</StepLabel>
                </Step>
                <Step>
                  <Circle completed={false} />
                  <StepLabel completed={false}>Received Payment</StepLabel>
                </Step>
              </StepContainer>
            </VerifyingCenter>
            <VerifyingBottom></VerifyingBottom>
          </PaymentVerifying>
        );
      case "success":
        return (
          <PaymentSuccess>
            <VerifyingTop>
              <BoldText>Transaction Confirmed</BoldText>
              <SmallText>
                The ticket details and transaction invoice will be sent to the email address you
                <br /> provided
              </SmallText>
            </VerifyingTop>
            <VerifyingCenter>
              <StepContainer>
                {/* Line from first circle to second circle */}
                <Line1 completed={true} />
                {/* Line from second circle to third circle */}
                <Line2 completed={false} />
                <Step>
                  <Circle completed={true} />
                  <StepLabel completed={true}>Initiated Transaction</StepLabel>
                </Step>
                <Step>
                  <Circle completed={true} />
                  <StepLabel completed={true}>Amount Sent</StepLabel>
                </Step>
                <Step>
                  <Circle completed={true} />
                  <StepLabel completed={true}>Received Payment</StepLabel>
                </Step>
              </StepContainer>
            </VerifyingCenter>
            <VerifyingBottom>
              <MediumText>
                Didn&apos;t receive it? <span style={{ color: "#1390E6", cursor: "pointer" }}>Send email again</span>
              </MediumText>
            </VerifyingBottom>
          </PaymentSuccess>
        );
      case "failed":
        return (
          <PaymentFailed>
            {" "}
            <FailureIcon />
            <FailureText>Ticket Purchase Failed</FailureText>
          </PaymentFailed>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (currentView === "verify") {
      let retryCount = 0;
      const maxRetries = 15;
      let isRetrying = false;

      const countdownInterval = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      const retryFunction = async () => {
        if (retryCount >= maxRetries || isRetrying) {
          if (retryCount >= maxRetries) {
            clearInterval(countdownInterval);
            clearInterval(retryInterval);
            updateCurrentView("failed");
          }
          return;
        }

        isRetrying = true; // Lock retry function to prevent overlap
        ticketPurchaseComplete(
          { sessionId: sessionid },
          {
            onSuccess: () => {
              clearInterval(countdownInterval);
              clearInterval(retryInterval);
              updateCurrentView("success");
            },
            onError: error => {
              console.error(`Retry attempt ${retryCount + 1} failed:`, error);
              retryCount++;
              isRetrying = false; // Unlock retry function after failure
            },
          },
        );
      };

      const retryInterval = setInterval(retryFunction, 20000);

      retryFunction(); // Immediate first attempt

      return () => {
        clearInterval(countdownInterval);
        clearInterval(retryInterval);
      };
    }
  }, [currentView, sessionid, ticketPurchaseComplete, updateCurrentView]);

  return (
    <Container>
      <Content>{renderContent()}</Content>
    </Container>
  );
};

export default Checkout;
