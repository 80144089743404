import React, { useState } from "react";
import styled from "styled-components";
import SearchBar from "ui/components/Dashboard/Filter/Searchbar";
import eventimage from "images/organizerevents/Rectangle1063(1).png";
import { ReactComponent as RightIcon } from "images/organizerevents/chevron-right.svg";
import { useGetEventExplore, useGetOrganizerEvents } from "lib/hooks";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";

const HomeContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #f9fafb;
  width: 480px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Category = styled.div`
  padding: 2px 5px;
  background-color: #fafbff;
  color: #1c5b87;

  border-radius: 8px;
  font-size: 9px;
  cursor: not-allowed;
`;

const Top = styled.div`
  margin-top: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const Search = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const EventList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const LargeText = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  margin: 0;
`;

const SmallText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #475467;
  margin: 0;
`;

const EventCard = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.3rem;
  justify-content: space-between;
  border: 1px solid #cecece;
  border-radius: 8px;
  background-color: #ffffff;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const EventImage = styled.img``;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const MediumBold = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
`;

const Tinytext = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #595959;
`;

const Right = styled.div``;

interface AttendeesHomeProps {
  searchTerm?: string;
}

const AttendeesHome: React.FC<AttendeesHomeProps> = ({ searchTerm = "" }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(12);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate(); // Initialize navigate function

  // const handleEventClick = (eventId: string) => {
  //   navigate(`${routeNames.attendeesEvent}/${eventId}`);
  // };
  const handleEventClick = (eventId: number) => {
    navigate(`${routeNames.attendeesEvent}/${eventId}`);
  };

  const {
    data: eventResponse,
    isLoading,
    isError,
  } = useGetOrganizerEvents({
    page: currentPage,
    limit: pageSize,
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching events</div>;

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    // Add logic to perform search with the query
  };

  return (
    <HomeContainer>
      <Top>
        <LargeText>Your Events</LargeText>
        <SmallText>Select an event to check-in attendees.</SmallText>
      </Top>
      <Search>
        <SearchBar onSearch={handleSearch} />
      </Search>

      <EventList>
        {eventResponse?.data?.map(event => (
          <EventCard key={event.id} onClick={() => handleEventClick(event.id)}>
            <Left>
              <EventImage src={eventimage} alt={event.title} />
              <EventDetails>
                <Category>{event.category || "upcoming"}</Category>
                <MediumBold>{event.title}</MediumBold>
                <Tinytext>Expected: 10,500</Tinytext>
                <Tinytext>{new Date(event.startDate).toDateString()}</Tinytext>
              </EventDetails>
            </Left>
            <Right>
              <RightIcon />
            </Right>
          </EventCard>
        ))}
      </EventList>

      {!isLoading && !isError && (
        <PaginationContainer>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={eventResponse?.totalCount || 0}
            onChange={handlePageChange}
            size="small"
          />
        </PaginationContainer>
      )}
    </HomeContainer>
  );
};

export default AttendeesHome;
