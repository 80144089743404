export const tqQueryKeys = {
  auth: {
    requestVerification: "auth/request-verification",
    verify: "auth/verify",
  },
  users: {
    getUsers: "/users",
    getUserById: "/users/:id",
  },
  packages: {
    getVendorPackages: "vendorpackage/vendor-packages",
    getVendorPackageById: "vendorpackage/vendor-packages:id",
    getVendorPackagesByEmail: "vendorpackage/vendor-packages:email",
  },
  ticket: {
    getTicket: "ticket",
    getTicketById: "ticket/:id",
    getTicketByEventId: "ticket/find-by-eventId/:eventid",
    getByCode: "ticket-purchase/find-by-ticket-code",
    download: "ticket-purchase/download-ticket/:sessionId",
  },
  vendorRequest: {
    getVendorRequest: "vendor-request",
    getVendorRequestById: "vendor-request/:id",
  },
  event: {
    getEvents: "events",
    getExploreEvents: "events/explore",
    getEventById: "events/:id",
    getExploreEventById: "events/explore:id",
  },
  vendors: {
    getVendors: "vendors",
    getVendorById: "vendors/:id",
    getVendorByEmail: "vendors/get-by-email/:email",
  },
  organizer: {
    getOrganizer: "organizer",
    getOrganizerbyId: "organizer/:id",
    getOrganizerbyEmail: "organizer/get-by-email/:email",
    getByEmail: "organizer/get-organization/:email",
    getOrganiserProfile: "organizer",
    overview: "organizer/overview",
  },
  eventCategory: {
    getEventCategories: "event-category",
  },
  requests: {
    getRequests: "requests/getRequests",
    getRequestById: "requests/getRequestById",
  },
};

export const tqMutationKeys = {
  auth: {
    login: "auth/login",
    resetPassword: "auth/reset-password",
    requestVerification: "auth/request-verification",
    forgotPassword: "auth/forgot-password",
  },
  adminauth: {
    login: "admin-auth/login",
  },
  users: {
    signup: "users/signup",
    createProfile: "userType/create",
  },
  packages: {
    create: "vendorpackage/vendor-packages",
    edit: "vendorpackage/vendor-packages:id",
    delete: "vendorpackage/vendor-packages:id",
  },
  vendorRequest: {
    create: "vendor-request/create",
    edit: "vendor-request/:id",
    delete: "vendor-request/:id",
  },
  event: {
    create: "events/create",
    edit: "events/edit/:id",
    delete: "events/delete/:id",
    publish: "events/publish-status/update:id",
    uploadBanner: "event/banner-upload/:eventId",
  },
  vendor: {
    edit: "vendor/edit/:id",
    delete: "vendor/delete/:id",
    bankDetails: {
      edit: "vendor/bank-details/edit",
    },
    uploadImage: "vendor/picture-upload",
  },
  organizer: {
    edit: "organizer/edit/:id",
    update: "organizer/update-by-email/:email",
    delete: "organizer/delete/:id",
    requestPayout: "requests/organizer/request-payout/:eventId/:organizerEmail",
    uploadAvatar: "organizer/picture-upload",
  },
  organizersetting: {
    addBankDetails: "organizer-settings/:bankdetails",
  },
  organization: {
    create: "organization/create",
  },
  ticket: {
    create: "ticket.create",
    edit: "ticket/edit/:id",
    delete: "ticket/delete/:id",
    initiate: "ticket/initiate",
    complete: "ticket/complete",
    checkIn: "ticket-purchase/check-in",
  },
  requests: {
    deleteRequestById: "requests/deleteRequestById",
  },
};

export const sessionStorageKeys = {
  userId: "user",
  accessToken: "accessToken",
  refreshToken: "refreshToken",
  email: "email",
  userType: "userType",
  signupUserType: "signupUserType",
  signupEmail: "signupEmail",
  signupKey: "key",
  requestedVerification: "requestedVerification",
  eventId: "eventId",
  ticketcheckout: "ticketcheckout",
  encodedCredentials: "authCredentials",
};
