import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

export interface UploadOrganizerImagePayload {
  avatar: File; // The avatar image file
}

export interface UploadOrganizerImageResponse {
  avatarUrl: string; // URL of the uploaded avatar
}

type UseUploadOrganizerImageResult = UseMutationResult<
  UploadOrganizerImageResponse,
  Error,
  UploadOrganizerImagePayload
>;

export function useUploadOrganizerImage(): UseUploadOrganizerImageResult {
  return useMutation({
    mutationFn: async (payload: UploadOrganizerImagePayload) => {
      const formData = new FormData();
      formData.append("avatar", payload.avatar); // Add the File object

      const res = await EvendyApi.post<UploadOrganizerImageResponse>("organizer/picture-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set multipart headers
        },
      });
      return res.data; // Automatically unwrap the Promise
    },
    mutationKey: [tqMutationKeys.organizer.uploadAvatar],
    onError(uploadAvatarError: Error) {
      console.error("Error uploading organizer avatar:", uploadAvatarError);
    },
    onSuccess(data) {
      console.log("Organizer avatar uploaded successfully:", data);
    },
  });
}
