import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Input, InputRef } from "antd";
import { useTicketContext } from "lib/context/ticketpurchase/ticketContext";
import { routeNames } from "lib/routes";

const Container = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  padding-right: 0.5rem;
`;

const Top = styled.div``;

const Name = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 10px);
`;

const Email = styled.div`
  width: calc(100% - 10px);
`;

const PhoneNumber = styled.div`
  width: 100%;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`;

const Cancel = styled.button`
  background-color: #ffffff00;
  color: #595959;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  padding: 10px 10px;
  font-size: 13px;
  width: 129px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Continue = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 129px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const YourDetails: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);
  const params = useParams<{ id?: string }>();
  const [eventId, setEventId] = useState<number>(0);

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : undefined;
    if (id) setEventId(id);
  }, [params.id]);

  const onFieldsChange = () => {
    const isValid = form.getFieldsError().every(field => field.errors.length === 0);
    setIsFormValid(isValid);
  };

  const onCancel = () => {
    navigate(routeNames.ticketSelect);
  };

  const { updateTicketData, updateCurrentView } = useTicketContext();

  const onNext = () => {
    form.validateFields().then(values => {
      updateTicketData({ attendee: values });
      updateCurrentView("price"); // Update the current view in the context
      navigate(`${routeNames.ticketCheckout}/${eventId}`);
    });
  };

  return (
    <Container>
      <Top>
        <Form layout="vertical" form={form} name="register" scrollToFirstError onFieldsChange={onFieldsChange}>
          <Name>
            <Form.Item label="First Name" style={{ width: "47.5%" }} name="firstName" rules={[{ required: true }]}>
              <Input placeholder="e.g John" style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }} />
            </Form.Item>
            <Form.Item label="Last Name" style={{ width: "47.5%" }} name="lastName" rules={[{ required: true }]}>
              <Input placeholder="e.g Doe" style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }} />
            </Form.Item>
          </Name>
          <Email>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { type: "email", message: "The input is not valid E-mail!" },
                { required: true, message: "Please input your E-mail!" },
              ]}
            >
              <Input
                placeholder="e.g example@email.com"
                style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
              />
            </Form.Item>
          </Email>
          <PhoneNumber>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                { required: true, message: "Please input your phone number!" },
                {
                  validator(_, value) {
                    if (!value) return Promise.reject(new Error("Phone number is required"));
                    if (!/^\d{11}$/.test(value))
                      return Promise.reject(new Error("Phone number must be exactly 11 digits"));
                    if (!/^0/.test(value)) return Promise.reject(new Error("Phone number must start with a 0"));
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="e.g 08061234567" style={{ fontSize: "14px", color: "#9C9C9C" }} />
            </Form.Item>
          </PhoneNumber>
        </Form>
      </Top>
      <Bottom>
        <Cancel onClick={onCancel}>Cancel</Cancel>
        <Continue onClick={onNext} disabled={!isFormValid}>
          Continue
        </Continue>
      </Bottom>
    </Container>
  );
};

export default YourDetails;
