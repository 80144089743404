import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

export type AddBankDetailsPayload = {
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  bankCode: number;
  organizerEmail: string;
};

interface AddBankDetailsResponse {
  id: number;
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  bankCode: number;
  organizerEmail: string;
  createdAt: string;
  updatedAt: string;
}

type UseAddOrganizerBankDetailsResult = UseMutationResult<AddBankDetailsResponse, Error, AddBankDetailsPayload>;

export function useAddOrganizerBankDetails(): UseAddOrganizerBankDetailsResult {
  return useMutation({
    mutationFn: async (payload: AddBankDetailsPayload) => {
      const res = await EvendyApi.post<Promise<AddBankDetailsResponse>>("organizer-settings/bank-details", payload);
      return res.data;
    },
    mutationKey: [tqMutationKeys.organizersetting.addBankDetails],
    onError(error) {
      console.error("Error adding bank details:", error);
    },
    onSuccess(data) {
      console.log("Bank details added successfully:", data);
    },
  });
}
