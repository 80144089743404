import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

export interface UploadVendorImagePayload {
  image: File; // The image file
  imageType: string; // The type of image (e.g., "avatar")
}

export interface UploadVendorImageResponse {
  imageUrl: string; // URL of the uploaded image
}

type UseUploadVendorImageResult = UseMutationResult<UploadVendorImageResponse, Error, UploadVendorImagePayload>;

export function useUploadVendorImage(): UseUploadVendorImageResult {
  return useMutation({
    mutationFn: async (payload: UploadVendorImagePayload) => {
      const formData = new FormData();
      formData.append("image", payload.image); // Add the File object

      const res = await EvendyApi.post<UploadVendorImageResponse>(
        `vendor/picture-upload?imageType=${payload.imageType}`, // Include imageType as a query parameter
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set multipart headers
          },
        },
      );
      return res.data; // Automatically unwrap the Promise
    },
    mutationKey: [tqMutationKeys.vendor.uploadImage],
    onError(uploadImageError: Error) {
      console.error("Error uploading vendor image:", uploadImageError);
    },
    onSuccess(data) {
      console.log("Vendor image uploaded successfully:", data);
    },
  });
}
