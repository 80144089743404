import React, { useState } from "react";
import styled from "styled-components";
import step1 from "images/organizerevents/step1.png";
import step2 from "images/organizerevents/step2.png";
import step3 from "images/organizerevents/step3.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";
import { ReactComponent as Close } from "images/organizerevents/x-close.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 480px;
  margin-top: 1rem;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 2rem;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;

const StepsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const Step = styled.div``;

const ImageContainer = styled.img``;

const LargeText = styled.p`
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  color: #1d2939;
  margin: 0;
`;

const SmallText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #475467;
  margin: 0;
`;

const BlueText = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #1390e6;
  margin: 0;
`;

const SmallBoldText = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1d2939;
  margin: 0;
`;

const CloseBtn = styled.button`
  background-color: #ffffff;
  color: #595959;
  border: 1px solid;
  border-color: #e2e8f0;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const AttendeesHowItWorks = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <StyledLink to={routeNames.attendeesHome}>
        <CloseContainer>
          <Close />
        </CloseContainer>
      </StyledLink>
      <Top>
        <LargeText>How this works!</LargeText>
        <SmallText>
          We’ve made attendee check-in as easy as pie.
          <br />
          Following the simple steps below and you should
          <br /> have an amazing time.
        </SmallText>
      </Top>
      <StepsList>
        <Step>
          <ImageContainer src={step1}></ImageContainer>
          <BlueText>Step 1</BlueText>
          <SmallBoldText>Select the Event</SmallBoldText>
          <SmallText>
            Log in, pick your event from the list, and get
            <br /> started.
          </SmallText>
        </Step>
        <Step>
          <ImageContainer src={step2}></ImageContainer>
          <BlueText>Step 2</BlueText>
          <SmallBoldText>Two Ways to Check Attendees</SmallBoldText>
          <SmallText>
            1. Search by Ticket Code: Enter the ticket code to
            <br /> find attendee details.
            <br />
            2. Scan QR/Barcode: Use your camera or scanner
            <br /> to scan the ticket.
          </SmallText>
        </Step>
        <Step>
          <ImageContainer src={step3}></ImageContainer>
          <BlueText>Step 3</BlueText>
          <SmallBoldText>Verify the Ticket</SmallBoldText>
          <SmallText>
            Valid Ticket: Marked as &quot;Checked-In.&quot; Already Checked-In: Alert to avoid double entry. Invalid
            Ticket: Warning for fake or unrecognized tickets.
          </SmallText>
        </Step>
        <StyledLink to={routeNames.attendeesHome}>
          <CloseBtn>close</CloseBtn>
        </StyledLink>
      </StepsList>
    </Container>
  );
};

export default AttendeesHowItWorks;
