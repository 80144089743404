import { redirect } from "react-router-dom";
import { sessionStorageKeys } from "lib/constants";
import { getSession } from "lib/utils/utils";
import { routeLoaderKeys } from "./routeLoaderKeys";

type UserRole = "organizer" | "vendor" | "basic";

export async function adminLoader(routeKey: keyof typeof routeLoaderKeys) {
  // Check if encoded credentials exist in session storage
  const encodedCredentials = sessionStorage.getItem(sessionStorageKeys.encodedCredentials);

  // If encoded credentials are missing, redirect to login
  if (!encodedCredentials) {
    return redirect("/evendy");
  }

  // Get the session details from the stored accessToken
  const session = getSession();

  // If session doesn't exist, redirect
  if (!session) {
    return redirect("/evendy");
  }

  try {
    const decodedToken = session;
    console.log(decodedToken);
    if (!decodedToken.exp || typeof decodedToken.exp !== "number") {
      throw new Error("Invalid or missing expiration time in JWT");
    }

    const tokenExpiration = decodedToken.exp;
    const currentTimestamp = Math.floor(Date.now() / 1000);

    // Check if the token is expired
    if (tokenExpiration < currentTimestamp) {
      return redirect("/evendy");
    }

    const userType = decodedToken.userType;

    // Ensure the user type is allowed for the route
    if (!routeLoaderKeys[routeKey].includes(userType)) {
      return redirect("/evendy");
    }

    return { user: decodedToken };
  } catch (error) {
    console.error("Error decoding or verifying JWT:", error);
    return redirect("/evendy");
  }
}
