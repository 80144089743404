import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import Sidebar, { SidebarItem } from "ui/components/Dashboard/Sidebar/Sidebar";
import { ReactComponent as HomeIcon } from "images/sidebar/Framehome.svg";
import { ReactComponent as EventIcon } from "images/sidebar/Frameevents.svg";
import { ReactComponent as VendorIcon } from "images/sidebar/Shop-Filledvendor.svg";
import { ReactComponent as Organizers } from "images/sidebar/organizer.svg";
import { ReactComponent as VendorRequest } from "images/sidebar/requests.svg";
import { ReactComponent as Disputes } from "images/sidebar/disputes.svg";
import Topbar from "ui/components/base/Topbar/Topbar";
import { Button } from "antd";
import MobileTopBar from "ui/components/base/MobileTopBar/MobileTopBar";
import { routeNames } from "lib/routes";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%; /* Take full height of the screen */
  flex-grow: 1;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SidebarContainer = styled.div<{ isSidebarVisible: boolean }>`
  width: 18.5%;
  height: calc(100vh - 7rem); /* Full height minus top margin */
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
  /* For Chrome, Edge, Safari */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    margin-top: 6rem;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: white;
    transform: ${({ isSidebarVisible }) => (isSidebarVisible ? "translateX(0)" : "translateX(100%)")};
    transition: transform 0.3s ease-in-out;
    z-index: 2000;
  }
`;

const OutletContainer = styled.div`
  width: 81.5%;
  /* height: calc(100vh - 7rem);  */
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 5rem;
  padding-top: 2.5rem;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */

  /* For Chrome, Edge, Safari */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-grow: 1;
    margin-top: 0;
    padding-top: 3rem;
  }
`;

const TopbarWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
`;

const MobileTopbarWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 768px) {
    display: flex;
  }
`;

const DesktopTopbarWrapper = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const SidebarOverlay = styled.div<{ isSidebarVisible: boolean }>`
  display: none;
  @media (max-width: 768px) {
    display: ${({ isSidebarVisible }) => (isSidebarVisible ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Notice = styled.button`
  background-color: #fffefa;
  color: #665000;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 1rem;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Verify = styled(Button)``;

// Sidebar data for organizer user
const adminSidebarData: SidebarItem[] = [
  // Define sidebar items relevant to organizers
  {
    name: "Home",
    path: "/evendy/home",
    icon: HomeIcon,
  },
  {
    name: "Events",
    path: "/evendy/events",
    icon: EventIcon,
  },
  {
    name: "Organizers",
    path: "/evendy/organizers",
    icon: Organizers,
  },
  {
    name: "Vendors",
    path: "/evendy/vendors",
    icon: VendorIcon,
  },
  {
    name: "Request & Payments",
    path: "/evendy/vendorrequest",
    icon: VendorRequest,
  },
  {
    name: "Disputes",
    path: "/evendy/disputes",
    icon: Disputes,
  },
];

const AdminDashboard: React.FC = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Close the sidebar whenever the route changes on mobile view
  useEffect(() => {
    if (window.innerWidth <= 768 && isSidebarVisible) {
      setIsSidebarVisible(false);
      setIsSidebarOpen(false);
    }
  }, [location]);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleContinue = () => {
    setIsModalVisible(true);

    navigate(routeNames.VerifyEmail);
  };
  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  return (
    <DashboardContainer>
      <TopbarWrapper>
        <DesktopTopbarWrapper>
          <Topbar />
        </DesktopTopbarWrapper>
        <MobileTopbarWrapper>
          <MobileTopBar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        </MobileTopbarWrapper>
      </TopbarWrapper>

      {/* <SidebarOverlay isSidebarVisible={isSidebarVisible} onClick={toggleSidebar} /> */}
      <ContentContainer>
        <SidebarContainer isSidebarVisible={isSidebarVisible}>
          <Sidebar sidebarData={adminSidebarData} />
        </SidebarContainer>

        <OutletContainer>
          <Outlet />
        </OutletContainer>
      </ContentContainer>
    </DashboardContainer>
  );
};

export default AdminDashboard;
