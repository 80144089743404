import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useRouteError } from "react-router-dom";
import { PageErrorBoundary } from "ui/components/sections";
import { useGetUsers, useGetVendorById } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import { ReactComponent as Totalevent } from "images/organizerevents/Icontotalevents.svg";
import { ReactComponent as Eventconcluded } from "images/organizerevents/Vectoreventsconcluded.svg";
import { ReactComponent as Upcomingevents } from "images/organizerevents/Iconupcomingevents.svg";
import { ReactComponent as Totalrevenue } from "images/organizerevents/Frametotalrevenue.svg";
import { ReactComponent as Hide } from "images/organizerevents/Framehide.svg";
import { ReactComponent as Package } from "images/vendor/package.svg";
import { ReactComponent as Check } from "images/vendor/Check.svg";
import { ReactComponent as Step } from "images/vendor/stepping_up.svg";
import { ReactComponent as Logo } from "images/vendor/Logomark.svg";
import { ReactComponent as Left11 } from "images/vendor/Vector.svg";
import { ReactComponent as Right11 } from "images/vendor/Plant.svg";

const Container = styled.div`
  margin: 1rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    margin: 0 1rem;
  }
`;

const Top = styled.div`
  margin-top: -1.4rem;
  @media (max-width: 768px) {
    margin-top: 3rem;

    width: calc(100% - 2rem);
  }
`;

const Header = styled.h3`
  font-size: 32px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
`;

const First = styled.div`
  margin-top: 1rem;

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }
`;

const EventsInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const TotalEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Cardline1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;
const Cardline2 = styled.p`
  font-size: 20px;
  font-weight: 500;
`;
const Cardline3 = styled.p`
  font-size: 12px;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
`;

const EventsConcluded = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const UpcomingEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { Select } from "antd";
const { Option } = Select;

const MediumBold = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #121212;
`;

const Section2 = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }
`;

const Section3 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Chart1 = styled.div`
  background-color: #f9fbff;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  @media (max-width: 768px) {
    width: calc(100% - 1rem);
  }
`;

const Chart1Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const Guide = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const GuideItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;
const DataDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #9823e0; /* Change to your desired color */
  border-radius: 50%; /* Makes the element a circle */
  display: inline-block; /* Keeps the dot inline with other elements */
`;

const HighestDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #168f43; /* Change to your desired color */
  border-radius: 50%; /* Makes the element a circle */
  display: inline-block; /* Keeps the dot inline with other elements */
`;

const LowestDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #dd96ff; /* Change to your desired color */
  border-radius: 50%; /* Makes the element a circle */
  display: inline-block; /* Keeps the dot inline with other elements */
`;

const NoDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #eaeaea; /* Change to your desired color */
  border-radius: 50%; /* Makes the element a circle */
  display: inline-block; /* Keeps the dot inline with other elements */
`;
const data = [
  {
    name: "Jan",
    uv: 400,
    pv: 240,
  },
  {
    name: "Feb",
    uv: 300,
    pv: 139,
  },
  {
    name: "Mar",
    uv: 200,
    pv: 980,
  },
  {
    name: "Apr",
    uv: 278,
    pv: 390,
  },
  {
    name: "May",
    uv: 189,
    pv: 480,
  },
  {
    name: "Jun",
    uv: 239,
    pv: 380,
  },
  {
    name: "Jul",
    uv: 349,
    pv: 430,
  },
  {
    name: "Aug",
    uv: 349,
    pv: 430,
  },
  {
    name: "Sep",
    uv: 349,
    pv: 430,
  },
  {
    name: "Oct",
    uv: 349,
    pv: 430,
  },
  {
    name: "Nov",
    uv: 349,
    pv: 430,
  },
  {
    name: "Dec",
    uv: 349,
    pv: 430,
  },
];

//piechart
const piedata = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export async function loader() {
  return Promise.resolve({});
}

const AdminHome = () => {
  return (
    <Container>
      <Top>
        <Header>Welcome, Caleb</Header>
      </Top>
      <First>
        <EventsInfo>
          <TotalEvents>
            <Cardline1>
              <Totalevent />
              Total organizers
            </Cardline1>
            <Cardline2>20</Cardline2>
            <Cardline3>5 completed this month</Cardline3>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </TotalEvents>
          <EventsConcluded>
            <Cardline1>
              <Totalevent />
              Total vendors
            </Cardline1>
            <Cardline2>40</Cardline2>
            <Cardline3>8 completed this month</Cardline3>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </EventsConcluded>
          <UpcomingEvents>
            <Cardline1>
              <Totalevent />
              Total events created
            </Cardline1>
            <Cardline2>0</Cardline2>
            <Cardline3>0 created this month</Cardline3>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </UpcomingEvents>
        </EventsInfo>
      </First>
      <Section3>
        <Chart1>
          <Chart1Header>
            <MediumBold>Published events by numbers</MediumBold>
            <Guide>
              <GuideItem>
                <DataDot />
                <Cardline3>Data</Cardline3>
              </GuideItem>
              <GuideItem>
                <HighestDot />
                <Cardline3>Highest</Cardline3>
              </GuideItem>
              <GuideItem>
                <LowestDot />
                <Cardline3>Lowest</Cardline3>
              </GuideItem>
              <GuideItem>
                <NoDot />
                <Cardline3>No Data</Cardline3>
              </GuideItem>
            </Guide>

            <Select
              defaultValue="2024"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                { value: "2024", label: "2024" },
                { value: "2023", label: "2023" },
                { value: "2022", label: "2022" },
              ]}
            />
          </Chart1Header>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data} margin={{ top: 40, right: 10, left: 0, bottom: 0 }}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" padding={{ left: 30, right: 30 }} tickSize={0} tickMargin={10} />
              <YAxis axisLine={false} tickSize={0} tickMargin={10} />
              <Tooltip />

              <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </Chart1>
      </Section3>
    </Container>
  );
};

export default AdminHome;
