import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

export interface UploadBannerPayload {
  eventTitle: string;
  banner: File; // Assuming the banner is a File object
}

export interface UploadBannerResponse {
  banner: string; // URL of the uploaded banner
}

type UseUploadBannerResult = UseMutationResult<
  UploadBannerResponse,
  Error,
  { eventId: number; payload: UploadBannerPayload }
>;

export function useUploadBanner(): UseUploadBannerResult {
  return useMutation({
    mutationFn: async ({ eventId, payload }: { eventId: number; payload: UploadBannerPayload }) => {
      const formData = new FormData();
      formData.append("eventTitle", payload.eventTitle);
      formData.append("banner", payload.banner); // Add the File object

      const res = await EvendyApi.post<UploadBannerResponse>(`event/banner-upload/${eventId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set multipart headers
        },
      });
      return res.data; // Automatically unwrap the Promise
    },
    mutationKey: [tqMutationKeys.event.uploadBanner],
    onError(uploadBannerError: Error) {
      console.error("Error uploading banner:", uploadBannerError);
    },
    onSuccess(data) {
      console.log("Banner uploaded successfully:", data);
    },
  });
}
